import React, { useEffect, useState } from "react";
import "./fgCheckModal.css";
import addList from "../../../../assets/images/addList.svg";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { fetchDelistBOMFGData } from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/loader";
import { Checkbox } from "@mui/material";
import InnoflexDatePicker from "../../../../components/DatePicker/datePickerNew";
import { dateTimeFormat } from "../../../../utils";

const FGCheckModal = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [copiedData, setCopiedData] = useState("");
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [fgDelistDBData, setFGDelistDBData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addFgInputDate, setAddFgInputDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [referenceValue, setReferenceValue] = useState("");

  const {
    action,
    isEdit,
    handleSelectedFGData,
    isReset,
    handleResetSearchEnable,
  } = props;

  useEffect(() => {
    if (isReset) {
      setShowTable(false);
      setCopiedData("");
      setFGDelistDBData([]);
      setSelectedAll(false);
      handleResetSearchEnable(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (searchTerm !== "") {
      const filtered = fgDelistDBData.filter(
        (item) =>
          item.fgCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.fgName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.country.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFGDelistDBData(filtered);
    } else {
      setFGDelistDBData(filteredData);
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const okButtonClicked = () => {
    const result = [];

    fgDelistDBData
      .filter((item) => item.isSelected)
      .forEach((item) => {
        result.push({
          skuCode: item.fgCode,
          skuName: item.fgName,
          country: item.country,
          action: action,
          isSelected: item.isSelected,
          launchDate: addFgInputDate,
          reference: referenceValue,
        });
      });
    handleSelectedFGData(result);
    setAddFgInputDate("");
    setReferenceValue("");
  };

  const getDelistBOMFGCheckData = async (data) => {
    let res1 = await fetchDelistBOMFGData(props.projectId, data);
    if (res1.status === 200) {
      setFGDelistDBData(res1.data);
      setFilteredData(res1.data);
    }
    setIsLoading(false);
    handleResetSearchEnable(true);
  };

  const handleDataCheck = () => {
    const parsedData = parseCopiedData(copiedData);
    setIsLoading(true);
    getDelistBOMFGCheckData(parsedData);
    setShowTable(true);
  };

  const parseCopiedData = (data) => {
    const rows = data.trim().split("\n");
    const firstColumnValues = rows.map((row) => {
      const columns = row.split("\t");
      const firstColumnValue = columns[0];
      return firstColumnValue;
    });
    return firstColumnValues;
  };
  //capture the first column value and store it in a list of array object

  const onCheckBoxAllClicked = (e) => {
    setSelectedAll(!isSelectedAll);
    const updatedData = [...fgDelistDBData];
    if (!isSelectedAll) {
      updatedData.forEach((item) => {
        if (item.fgValid.split("")[1] !== "N") {
          item.isSelected = true;
        }
      });
    } else {
      updatedData.forEach((item) => (item.isSelected = false));
    }
    setFGDelistDBData(updatedData);
  };

  const onCheckBoxClicked = (e, index) => {
    const updatedData = [...fgDelistDBData];
    const item = updatedData[index];
    if (item && item.fgValid.split("")[1] !== "N") {
      item.isSelected = !item.isSelected;
      setFGDelistDBData(updatedData);
    }
    if (
      updatedData.filter(
        (item) => !item.isSelected && item.fgValid.split("")[1] === "Y"
      ).length
    ) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
    }
  };

  const handleAddDatePickerChange = (
    value,
    isNull,
    objInnoflexCountryItem,
    date,
    index
  ) => {
    setAddFgInputDate(dateTimeFormat(date, "MM/DD/YYYY"));
  };

  return (
    <>
      <div className="bwi-delistBOMfg-modal">
        <div className="bwi-delistBOMfg-table">
          {showTable ? (
            <div className="bwi-delistBOMfg-table-body">
              <Row className="bwi-delistBOMfg-table-header bwi-delistBOMfg-row-not-selected">
                <div
                  className="bwi-delistBOMfg-table-col"
                  style={{ width: "10%" }}
                >
                  <Checkbox
                    checked={isSelectedAll}
                    id={`default-checkbox`}
                    disabled={fgDelistDBData.every(
                      (item) => item.fgValid.split("")[1] === "N"
                    )}
                    onChange={(e) => onCheckBoxAllClicked(e)}
                    className="bwi-checkbox"
                    disableRipple
                  />
                </div>
                <div
                  style={{ width: "13%" }}
                  className="bwi-delistBOMfg-table-col dsf-text-left"
                >
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    Country
                  </span>
                </div>
                <div
                  style={{ width: "13%" }}
                  className="bwi-delistBOMfg-table-col dsf-text-left"
                >
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    FG Code
                  </span>
                </div>
                <div
                  style={{ width: "17%" }}
                  className="bwi-delistBOMfg-table-col dsf-text-left"
                >
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    FG Name
                  </span>
                </div>
                <div
                  className="bwi-delistBOMfg-table-col bwi-display-flex-between dsf-text-center"
                  style={{ width: "45%" }}
                >
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "33%" }}
                  >
                    FG Status
                  </span>
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "33%" }}
                  >
                    Sold Flag
                  </span>
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "33%" }}
                  >
                    FG Valid
                  </span>
                </div>
              </Row>
              <div className="bwi-delistBOMfg-table-data">
                {showTable && isLoading ? (
                  <ActivityIndicator />
                ) : (
                  fgDelistDBData.map((rowData, index) => (
                    <Row
                      className={`bwi-delistBOMfg-table-header ${
                        rowData.fgValid.split("")[1] === "N" || !isEdit
                          ? "dsf-delistBOMfg-txt-disable"
                          : !rowData.isSelected
                          ? "bwi-delistBOMfg-row-not-selected"
                          : "dsf-delistBOMfg-txt-enable"
                      }`}
                      key={index}
                    >
                      <div
                        className={`bwi-delistBOMfg-table-col ${
                          rowData.fgValid.split("")[1] === "N"
                            ? "dsf-delistBOMfg-checkbox-disable"
                            : ""
                        }`}
                        style={{ width: "10%" }}
                      >
                        <Checkbox
                          checked={rowData.isSelected ? true : false}
                          disabled={rowData.fgValid.split("")[1] === "N"}
                          id={`default-checkbox-${index}`}
                          onChange={(e) => onCheckBoxClicked(e, index)}
                          className="bwi-checkbox"
                          disableRipple
                        />
                      </div>
                      <div
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                        style={{ width: "13%" }}
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.country}
                        </span>
                      </div>
                      <div
                        style={{ width: "13%" }}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgCode}
                        </span>
                      </div>
                      <div
                        style={{ width: "17%" }}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgName}
                        </span>
                      </div>
                      <div
                        className="bwi-delistBOMfg-table-col bwi-display-flex-between dsf-text-center"
                        style={{ width: "45%" }}
                      >
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "33%" }}
                        >
                          {rowData.fgStatus}
                        </span>
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "33%" }}
                        >
                          {rowData.soldFlag}
                        </span>
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "33%" }}
                        >
                          {rowData.fgValid.split("")[0]}
                        </span>
                      </div>
                    </Row>
                  ))
                )}
              </div>
            </div>
          ) : (
            <textarea
              className="dsf-delistBOMfg-body-input-textarea"
              rows="2"
              cols="50"
              placeholder="Paste the codes here"
              value={copiedData}
              onChange={(e) => setCopiedData(e.target.value)}
              disabled={!isEdit}
            ></textarea>
          )}
        </div>
        {!showTable
          ? copiedData.length > 0 && (
              <Button
                variant="secondary"
                className="bw-delistBOMfg-btn bw-delistBOMfg-btn-position bw-delistBOMfg-run-btn"
                disabled={(copiedData.length > 0 ? false : true) || !isEdit}
                onClick={handleDataCheck}
              >
                Run
              </Button>
            )
          : fgDelistDBData.length > 0 && (
              <Row className="bwi-delisBOM-FGDate-row">
                <Col xs={8} style={{ display: "flex" }}>
                  <div
                    style={{ display: "flex" }}
                    className={
                      !fgDelistDBData.some((item) => item.isSelected)
                        ? "bwi-delisbom-date"
                        : ""
                    }
                  >
                    <div>
                      <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium bwi-delistbom-date-text">
                        Date
                      </p>
                      <div>
                        <InnoflexDatePicker
                          key={`add-date-picker-new-row-0`}
                          launchDate={addFgInputDate}
                          isValueNull={true}
                          onDatePickerChange={(value, isNull, date) =>
                            handleAddDatePickerChange(
                              value,
                              isNull,
                              [],
                              date,
                              0
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      !fgDelistDBData.some((item) => item.isSelected)
                        ? "bwi-delisbom-reference-disabled"
                        : "bwi-delisbom-reference"
                    }
                  >
                    <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium">
                      Reference
                    </p>
                    <input
                      className="bwi-delisbom-reference-input-text"
                      type="text"
                      placeholder="Add Reference"
                      value={referenceValue}
                      onChange={(e) => setReferenceValue(e.target.value)}
                    />
                  </div>
                </Col>

                <Col
                  xs={4}
                  style={{ textAlign: " -webkit-right" }}
                  className="bwi-delistbom-add-selected-button"
                >
                  <Button
                    variant="secondary"
                    className="bw-delistBOMfg-btn bw-delistBOMfg-run-btn"
                    onClick={okButtonClicked}
                    disabled={
                      !isEdit ||
                      !addFgInputDate ||
                      !referenceValue ||
                      !fgDelistDBData.some((item) => item.isSelected)
                    }
                  >
                    {" "}
                    <img
                      src={addList}
                      alt="addList"
                      style={{ marginRight: "8px", paddingTop: "2px" }}
                    />{" "}
                    Add Selected
                  </Button>
                </Col>
              </Row>
            )}
      </div>
    </>
  );
};

export default FGCheckModal;
