import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  Col,
  Row,
} from "react-bootstrap";

import ProjectLanding from "./projectLanding";

import ModalProjectInfo from "../ui-components/modalProjectInfo";
import ModalRNDSignOff from "../ui-components/RNDSignOff/modalRNDSignOff";
import DelistingProject from "../ui-components/DelistingProject/delistingProject";
import Tracker from "../ui-components/Tracker/tracker";
import ModalSnapshot from "../ui-components/SnapShot/modalSnapshot";
import ModalDeepDiveScreen from "../TaskflowScreens/DeepDiveView/modalDeepDiveView";
import TickMark from "../../../assets/images/tick.svg";
import Snapshot from "../../../assets/images/snapshot.svg";
import closeIcon from "../../../assets/images/Vector-cross.svg";
import {
  sortProjectList,
  deleteDelistingProject,
} from "../../../slices/projectDetailSlice";
import {
  innoflexStatusEnum,
  sortProjectEnum,
  delistingActionMenus,
  innoflexStatusColorsEnum,
  innoflexStatusNamesEnum,
} from "../../../globalConstants";
import { handleSort, formatNumber } from "../../../utils";
import { appInsightsService } from "../../../appInsights";
import { ProjectInfoTooltip } from "../../../components/Tooltip/tooltip";
import CustomMenus from "../../../components/Menu/customMenu";
import CustomizedSteppers from "../../../components/Stepper/stepperDivision";
import { myProjectList } from "../../../slices/sortProjectDetailsSlice";
import { configByEnv } from "../../../service/APIHelper";

const WorkFlowList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showRDModal, setShowRDModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState(false);
  const [isDeepDiveModalOpen, setIsDeepDiveModalOpen] = useState(false);
  const [isTrackerModalOpen, setIsTrackerModalOpen] = useState(false);
  const [isSortWindowOpen, setIsSortWindowOpen] = useState(false);
  //const [activeSortFunc, setActiveSortFunc] = useState("");
  const [mySortData, setMySortData] = useState([]);
  const sortDivRef = useRef(null);
  const [showDelistingProjectModal, setShowDelistingProjectModal] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState({
    projectId: "",
    projectName: "",
  });
  const [showProjLanding, setShowProjLanding] = useState(false);
  const [seletedUser, setSeletecUser] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [isEditProject, setIsEditProject] = useState(false);
  const [istext, setIsText] = useState("");
  let sortingRef = useRef(false);
  const [reload, setReload] = useState(false);
  const { activeSortFunc, setActiveSortFunc } = props;
  const trackerAccessRole = [
    "BWInnovationP&I",
    "BWInnovationP&IAdmin",
    "BWInnovationE2EPlanner",
    "BWInnovationDemandPlanner",
    "BWInnovationMaterialPlanner",
    "BWInnovationProcurement",
    "BWInnovationMarketing",
  ];

  //Redux Start
  const dispatch = useDispatch();
  const arrProjectDetails = useSelector((state) => state.projectDetails);
  const { allProjectDetails } = useSelector(
    (state) => state.sortProjectDetails
  );
  const userDetails = useSelector((state) => state.userDetails);

  //Redux End

  const updateNewProjectList = () => {
    props.updateProjectList();
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (sortDivRef.current && !sortDivRef.current.contains(event.target)) {
        setIsSortWindowOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortDivRef]);

  useEffect(() => {
    if (istext === "excludeStyle") {
      document.querySelector("body").removeAttribute("style");
      setIsText("");
    }
  }, [istext]);

  useEffect(() => {
    if (props.arrProject.length > 0 && !sortingRef.current) {
      changeActiveSort("Business Waste - high to low");
      props.setProjectSorting(true);
      sortingRef.current = true;
    }
  }, [props.arrProject]);

  useEffect(() => {
    if (!props.projectSorting) {
      changeActiveSort("Business Waste - high to low");
      sortingRef.current = false;
    }
  }, [props.projectSorting, props.showDelistingProjectModal]);

  const showUpdatedWorkflowModal = (objWorkflowStage, i, e) => {
    if (objWorkflowStage.status !== "1") {
      let index = e.findIndex(
        (item) => item.workflowId === objWorkflowStage.workflowId
      );
      setSelectedWorkflow(index);
      setCurrentItem(i);
      setShowProjLanding(true);
    }
  };

  const handleDelistingProjectDelete = async (
    selectedprojectId,
    selectedprojectName
  ) => {
    setIsDeleteModalOpen(true);
    setSelectedForDelete({
      projectId: selectedprojectId,
      projectName: selectedprojectName,
    });
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleMenuSelection = (menu, item, i) => {
    sortingRef.current = false;
    setSelectedForDelete({
      projectId: item.projectId,
      projectName: item.projectName,
    });
    setCurrentItem(i);
    if (menu === "Project Summary") {
      setShowModal(!showModal);
    } else if (menu === "Edit Project") {
      setIsEditProject(true);
      setShowDelistingProjectModal(true);
    } else if (menu === "Delete Project") {
      setIsDeleteModalOpen(true);
    }
  };

  const onOkDeleteBtnClicked = async () => {
    setIsDeleteModalOpen(false);
    const res = await dispatch(
      deleteDelistingProject(selectedForDelete.projectId)
    ).unwrap();
    props.updateProjectList();
  };

  const handleStepSelection = (index, item) => {
    let isSameUser =
      arrProjectDetails[item].projectCreatedByOwner === userDetails.email;
    let ListusersHasAccess = arrProjectDetails[item].usersHasAccess.split(",");
    console.log("ListusersHasAccess", ListusersHasAccess);
    const isUserHasAccess = ListusersHasAccess.includes(
      userDetails.email?.toLowerCase()
    );
    setUserHasAccess(isUserHasAccess);
    setSeletecUser(isSameUser);
    setSelectedWorkflow(index);
    setCurrentItem(item);
    setShowProjLanding(true);
  };

  const handleBarSelection = (e, index, item) => {
    e.stopPropagation();
    if (!e.target.closest(".bwi-workflow-selection-non-click")) {
      let isSameUser =
        arrProjectDetails[item].projectCreatedByOwner === userDetails.email;
      let ListusersHasAccess =
        arrProjectDetails[item].usersHasAccess.split(",");
      const isUserHasAccess = ListusersHasAccess.includes(
        userDetails.email?.toLowerCase()
      );
      setUserHasAccess(isUserHasAccess);
      setSeletecUser(isSameUser);
      setSelectedWorkflow(false);
      setCurrentItem(item);
      setShowProjLanding(true);
    }
  };

  const trackAppInsight = (workflowName, currentItem) => {
    const properties = {
      workflowName: workflowName,
      projectName: arrProjectDetails[currentItem].projectName,
    };
    // appInsightsService.appInsights.trackEvent({ name: `${workflowName} clicked by ${usermailId}`, properties: properties_event })
    appInsightsService.appInsights.trackPageView({ properties: properties });
  };

  const onDeepDiveButtonClicked = (item, i) => {
    setIsDeepDiveModalOpen(true);
    setCurrentItem(i);
  };

  const onSnapshotBtnClicked = (item, i) => {
    if (item?.isSnapshotEnabled) {
      setIsSnapshotModalOpen(true);
      setCurrentItem(i);
    }
  };

  const updateProjectList = (e) => {
    if (e) {
      props.updateProjectList();
    }
  };

  const onTrackerBtnClicked = (item, i) => {
    // if (item.workflow[8].status === "2") {
    setIsTrackerModalOpen(true);
    setCurrentItem(i);
    // }
  };

  const onSortBtnClicked = () => {
    setIsSortWindowOpen(true);
  };
  const changeActiveSort = async (item) => {
    setActiveSortFunc(item);
    setIsSortWindowOpen(false);
    let arrProjectDetailsBeforeSort = [...arrProjectDetails];
    let sortedArray = [];
    switch (item) {
      case "Business Waste - high to low":
        sortedArray = await handleSort(
          arrProjectDetails,
          "descending",
          "systemBW",
          false
        );
        dispatch(sortProjectList(sortedArray));
        break;
      case "Business Waste - low to high":
        sortedArray = await handleSort(
          arrProjectDetails,
          "ascending",
          "systemBW",
          false
        );
        dispatch(sortProjectList(sortedArray));
        break;
      case "Project Name A - Z":
        sortedArray = await handleSort(
          arrProjectDetails,
          "ascending",
          "projectName",
          true
        );
        dispatch(sortProjectList(sortedArray));
        break;
      case "Project Name Z - A":
        sortedArray = await handleSort(
          arrProjectDetails,
          "descending",
          "projectName",
          true
        );
        dispatch(sortProjectList(sortedArray));
        break;
      case "My Projects":
        setActiveSortFunc(item);
        setMySortData(arrProjectDetails);
        let arrMyProjects = arrProjectDetails.filter((project) => {
          return project.projectCreatedByOwner === userDetails.email;
        });
        props.filterProjectList(arrMyProjects);
        dispatch(sortProjectList(arrMyProjects));
        break;
      case "All Projects":
        setActiveSortFunc(item);
        dispatch(sortProjectList(allProjectDetails));
        props.filterProjectList(allProjectDetails);
        break;
    }
  };

  const prepareSortWindow = () => {
    return (
      <>
        <div
          ref={sortDivRef}
          className={"modal-sort-window-new"}
          style={{ display: isSortWindowOpen ? "block" : "none" }}
        >
          <span
            className="dsf-size8 dsf-font-weight-400"
            style={{ color: "#cfcfcf" }}
          >
            Sort by
          </span>
          <ul>
            {Object.values(sortProjectEnum).map((item, index) => {
              return (
                <li key={index} onClick={() => changeActiveSort(item)}>
                  <span className="dsf-size12 dsf-font-weight-600">
                    {" "}
                    {item}{" "}
                  </span>
                  {item === activeSortFunc && (
                    <img src={TickMark} alt="TickMark" />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      {isSortWindowOpen && prepareSortWindow()}

      {isTrackerModalOpen && (
        <Tracker
          isShow={true}
          selectedProjectId={arrProjectDetails[currentItem].projectId}
          userRole={userDetails.roleName}
          onComplete={() => setIsTrackerModalOpen(false)}
        />
      )}

      {isSnapshotModalOpen && (
        <ModalSnapshot
          showModal={true}
          onComplete={() => setIsSnapshotModalOpen(false)}
          selectedProjectId={arrProjectDetails[currentItem].projectId}
          // headerBgColor={props.setHeaderBgColor()}
        />
      )}
      {isDeepDiveModalOpen && (
        <ModalDeepDiveScreen
          showModal={true}
          onComplete={() => setIsDeepDiveModalOpen(false)}
          selectedProjectId={arrProjectDetails[currentItem].projectId}
          selectedProjectName={arrProjectDetails[currentItem].projectName}
          arrItem={arrProjectDetails[currentItem]}
          isEdit={props.isEdit}
        />
      )}
      {showRDModal && (
        <ModalRNDSignOff
          showModal={true}
          setShowModal={(isModalOpen) => setShowRDModal(isModalOpen)}
        />
      )}
      {showDelistingProjectModal && (
        <DelistingProject
          showModal={true}
          setShowModal={(isModalOpen) => {
            setShowDelistingProjectModal(isModalOpen);
            setIsText("excludeStyle");
          }}
          updateNewProjectList={updateNewProjectList}
          userName={userDetails.email}
          isDataUpdate={isEditProject ? true : false}
          projectId={isEditProject ? selectedForDelete.projectId : null}
        />
      )}
      {showModal && (
        <ModalProjectInfo
          showModal={showModal}
          setShowModal={() => {
            setShowModal(false);
            setIsText("excludeStyle");
          }}
          selectedProjectName={arrProjectDetails[currentItem].projectName}
        />
      )}
      {showProjLanding && (
        <ProjectLanding
          showModal={showProjLanding}
          setShowModal={setShowProjLanding}
          selectedProjectName={arrProjectDetails[currentItem].projectName}
          selectedWorkflow={selectedWorkflow}
          arrItem={arrProjectDetails[currentItem]}
          isEdit={seletedUser}
          updateNewProjectList={updateNewProjectList}
          userHasAccess={userHasAccess}
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          centered
          dialogClassName="dsf-delistingProjDel-okdialog dsf-dialog-messagebox"
          contentClassName="dsf-modal-messagebox dsf-delistingProjDel-okmodal"
          show={isDeleteModalOpen}
        >
          <Modal.Header className="bw-modal-messagebox-header">
            <Modal.Title className="dsf-size16 bw-messagebox-header-title bw-warnmodal-header-title dsf-title-color">
              Delete Project
            </Modal.Title>
            <img
              src={closeIcon}
              alt="Close-Image"
              className="img.dsf-close-messagebox-img"
              onClick={() => {
                closeDeleteModal(false);
                setIsText("excludeStyle");
              }}
            />
          </Modal.Header>
          <Modal.Body className="bw-modal-messagebox-body">
            <p className="bw-modal-messagebox-msg">{`Are you sure you want to delete '${selectedForDelete.projectName}' project?`}</p>
          </Modal.Body>
          <Modal.Footer className="bw-modal-footer">
            <Button
              className="bw-footer-cancle-btn"
              variant="secondary"
              onClick={() => {
                closeDeleteModal();
                setIsText("excludeStyle");
              }}
            >
              Cancel
            </Button>
            <Button
              className="dsf-footer-okbutton-PI"
              variant="secondary"
              onClick={() => onOkDeleteBtnClicked()}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="dsf-dashbaord-project-summary-div-new">
        <Row className="project-details-head">
          <Col xs={3} className="dsf-sortby-col">
            <Row>
              <Col xs={1}>
                <img
                  src={require("../../../assets/images/SortIcon.svg").default}
                  alt="sort"
                  className="dsf-sort-icon-New"
                  onClick={() => onSortBtnClicked()}
                />
              </Col>
              <Col xs={10}>
                <span className="dsf-sortby-title dsf-color-EDF1F3">
                  Sort or filter projects by
                </span>
              </Col>
            </Row>
            <Row>
              <hr className="horizontal-line" />
            </Row>
          </Col>
          <Col xs={7} className="dsf-workflow-stages-col">
            <Row className="dsf-workflow-stages-row">
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                INPUT FGs
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                Review Dates
              </Col>
              <Col
                xs={1}
                className="dsf-sortby-title dsf-color-CFCFCF"
                style={{ paddingRight: "10px" }}
              >
                REVIEW SUs & CMs
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                REVIEW UNIQUE RPMs
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                Input Supplier Stock
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                Baseline BW#
              </Col>
              <Col
                xs={2}
                className="dsf-sortby-title dsf-color-CFCFCF"
                style={{ maxWidth: "10%", marginRight: "35px" }}
              >
                Deep-dive System BW (m€)
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                E-mail E2E
              </Col>
              <Col xs={1} className="dsf-sortby-title dsf-color-CFCFCF">
                Plan Scenario
              </Col>
              <Col
                xs={1}
                className="dsf-sortby-title dsf-color-CFCFCF"
                style={{ paddingRight: "0px" }}
              >
                Scenario BW (m€)
              </Col>
            </Row>
            <Row className="dsf-workflow-stages-row">
              <Col xs={1} className="action-tracker-col">
                <p className="color-dot-white color-line-white color-line-gray-before"></p>
              </Col>
              <Col xs={1} className="action-tracker-col">
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col
                xs={1}
                className="action-tracker-col "
                // style={{ paddingRight: "10px" }}
              >
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-gray-BW"></span>
              </Col>
              <Col
                xs={2}
                className="action-tracker-col "
                style={{ maxWidth: "10%", marginRight: "35px" }}
              >
                <span className="color-dot-white color-line-gray-DW"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-white"></span>
              </Col>
              <Col xs={1} className="action-tracker-col ">
                <span className="color-dot-white color-line-gray-Action"></span>
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            <Row className="action-row">
              <Col xs={4} className="dsf-sortby-title dsf-color-CFCFCF">
                Snapshot
              </Col>
              <Col xs={5} className="dsf-sortby-title dsf-color-CFCFCF">
                Action Tracker
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row className="action-row">
              <Col xs={4} className="action-tracker-col">
                <p className="color-dot-white color-line-gray-Action"></p>
              </Col>
              <Col xs={5} className="action-tracker-col">
                <span className="color-dot-white color-line-gray"></span>
              </Col>
              <Col xs={2}></Col>
            </Row>
          </Col>
        </Row>
        {arrProjectDetails.map((item, i) => {
          const imgSrc = innoflexStatusEnum[item.innoflexStatus];
          const statusColorDiv = innoflexStatusColorsEnum[item.innoflexStatus];
          const statusName = innoflexStatusNamesEnum[item.innoflexStatus];
          return (
            <Row
              key={item.tempId}
              className="project-details-list-new"
              onClick={(e) => {
                handleBarSelection(e, false, i);
              }}
            >
              <div className="projectInfo-status-div">
                <div
                  className="innoflex-status-div"
                  style={{ backgroundColor: `${statusColorDiv}` }}
                >
                  <div className="innoflex-div-rotate">
                    {imgSrc !== "Post-Launch" && (
                      <img
                        src={require(`../../../assets/images/${imgSrc}.svg`)}
                        alt="sort"
                        // className="dsf-status-icon"
                      />
                    )}
                    <span className="innoflex-status-title">{statusName}</span>
                  </div>
                </div>
                <Col xs={10} style={{ paddingLeft: "6px" }}>
                  {item.projectDesc.length > 32 ||
                  item.projectName.length > 22 ? (
                    <div className="project-info-new">
                      <ProjectInfoTooltip
                        item={item}
                        showModal={setShowModal}
                        index={i}
                        currentItem={setCurrentItem}
                        appInsight={trackAppInsight}
                        projNameLength={22}
                        projDescLength={32}
                      />
                    </div>
                  ) : (
                    <div className="project-info-new proj-123">
                      <div className="dsf-dashbaord-project-detail-div">
                        <span className="dsf-dashbaord-project-title-span dsf-span-m">
                          {item.projectName}
                        </span>
                      </div>
                      <span className="dsf-dashbaord-project-desc-span dsf-span-s">
                        {" "}
                        {item.projectDesc}
                      </span>
                    </div>
                  )}
                </Col>
              </div>
              <Col xs={7}>
                <Row className="dsf-workflow-stages-row">
                  <Col
                    xs={5}
                    className="dsf-first-stepper bwi-workflow-selection-non-click"
                    style={{ padding: "0px" }}
                  >
                    {/* <div> */}
                    <CustomizedSteppers
                      key={i}
                      arrItem={item}
                      onStepSelection={(e) => handleStepSelection(e, i)}
                      listClassName={"bwi-stepper-homepage"}
                      startValue={0}
                      EndValue={5}
                    />
                    {/* </div> */}
                  </Col>
                  <Col
                    xs={1}
                    className="dsf-BW-value dsf-BW-value-base"
                    style={{ color: "#FFFFFF" }}
                  >
                    {formatNumber(item.baseLineBW)}
                  </Col>
                  <Col
                    xs={2}
                    className="dsf-BW-value bwi-workflow-selection-non-click dsf-BW-value-system bwi-landing-cursor-pointer"
                    style={{
                      maxWidth: "10%",
                      marginRight: "35px",
                      color: "#00D6C8",
                    }}
                    onClick={() => onDeepDiveButtonClicked(item, i)}
                  >
                    {formatNumber(item.systemBW)}
                  </Col>
                  <Col
                    xs={2}
                    className="dsf-secont-stepper bwi-workflow-selection-non-click"
                    style={{ padding: "0px" }}
                  >
                    <CustomizedSteppers
                      key={i}
                      arrItem={item}
                      onStepSelection={(e) => handleStepSelection(e, i)}
                      listClassName={"bwi-stepper-homepage"}
                      startValue={5}
                      EndValue={7}
                    />
                  </Col>

                  <Col
                    xs={1}
                    className="dsf-BW-value"
                    style={{ paddingLeft: "0px", color: "#FFFFFF" }}
                  >
                    {formatNumber(item.scenarioBW)}
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <Row className="action-row">
                  <Col xs={3} className="action-tracker-col">
                    <img
                      src={Snapshot}
                      alt="snapshot-icon"
                      className={`bwi-workflow-selection-non-click ${
                        item.isSnapshotEnabled
                          ? "snapshot-enabled"
                          : "snapshot-disabled"
                      }`}
                      onClick={() => onSnapshotBtnClicked(item, i)}
                    />
                  </Col>
                  <Col
                    xs={5}
                    className="action-tracker-col"
                    style={{ paddingRight: "0px" }}
                  >
                    <img
                      src={Snapshot}
                      alt="tracker-icon"
                      className="bwi-landing-cursor-pointer bwi-workflow-selection-non-click"
                      onClick={() => onTrackerBtnClicked(item, i)}
                    />
                  </Col>
                  <Col
                    xs={3}
                    className="project-summary-editable-col bwi-workflow-selection-non-click"
                  >
                    <CustomMenus
                      listClassName="bwi-proj-info-more-icon"
                      variant="MoreButton"
                      menuItems={
                        item.projectCreatedByOwner !== userDetails.email &&
                        (userDetails.roleName === "BWInnovationP&I" ||
                          !props.isEdit ||
                          configByEnv().sampleProjectNameSK === item.tempId)
                          ? [delistingActionMenus[0]]
                          : delistingActionMenus
                      }
                      onMenuItemClick={(menu) =>
                        handleMenuSelection(menu, item, i)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
};

export default WorkFlowList;
