import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import "./uniqueRPM.css";
import { handleSort } from "../../../../utils";
import AutoCompleteFilter from "../../../../components/Autocomplete/autocompleteFilter";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import polygon from "../../../../assets/images/Polygon-enable.svg";
import polygonClose from "../../../../assets/images/PolygonClose.svg";
import { Checkbox } from "@mui/material";
import { uniqueRPMFilterEnum } from "../../../../globalConstants";
import ModalRedundantDetails from "./modalRedundantError";

const UniqueRPM = (props) => {
  const [objUniqueRPM, setObjUniqueRPM] = useState(props.objUniqueRPM);
  const [objFilteredRPM, setObjFilteredRPM] = useState(props.objUniqueRPM);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const {
    isEdit,
    isReceipientEnable,
    isRNDPIUser,
    isCheckboxDisabled,
    selectionValueChange,
    isDataNotAvailable,
  } = props;
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [skuCodeIsOpen, setSkuCodeIsOpen] = useState(false);
  const [skuNameIsOpen, setSkuNameIsOpen] = useState(false);
  const [plantCodeIsOpen, setPlantCodeIsOpen] = useState(false);
  const [plantNameIsOpen, setPlantNameIsOpen] = useState(false);
  const [materialTypeIsOpen, setMaterialTypeIsOpen] = useState(false);
  const [recipientIsOpen, setRecipientIsOpen] = useState(false);
  const [rpmPlannerIsOpen, setRpmPlannerIsOpen] = useState(false);
  const [e2ePlannerIsOpen, setE2ePlannerIsOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [gridData, setGridData] = useState({});
  const [changeStatus, setChangeStatus] = useState({});
  const [rpmFilterEnum, setRPMFilterEnum] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setFilterData({});
    setGridData({});
    setIsLoading(props.isLoading);
    setObjUniqueRPM(props.objUniqueRPM);
    setObjFilteredRPM(props.objUniqueRPM);
    //handleInitialFilterData(props.objUniqueRPM);
    setSelectedAll(false);
  }, [props.isLoading, props.objUniqueRPM, props.tabChange]);

  useEffect(() => {
    let updatedEnum = { ...uniqueRPMFilterEnum };
    if (isReceipientEnable) {
      updatedEnum = {
        ...updatedEnum,
        7: "recipientEmail",
      };
      setRPMFilterEnum(updatedEnum);
    }
    setRPMFilterEnum(updatedEnum);
    handleInitialFilterData(props.objUniqueRPM, updatedEnum);
  }, [isReceipientEnable, props.objUniqueRPM]);

  const handleInitialFilterData = (obj, updatedEnum) => {
    if (obj && obj.uniqueRMPMDetail && obj.uniqueRMPMDetail.length > 0) {
      let filterDataInitial = {};

      Object.values(updatedEnum).forEach((filterType) => {
        filterDataInitial[filterType] = {
          selectedData: [],
          checkData: [],
          optionData: [],
          modifiedFilter: false,
        };
      });

      obj.uniqueRMPMDetail.forEach((item) => {
        Object.values(updatedEnum).forEach((filterType) => {
          if (item[filterType] !== undefined) {
            filterDataInitial[filterType].selectedData = [
              ...new Set([
                ...filterDataInitial[filterType].selectedData,
                item[filterType],
              ]),
            ];
          }
        });
      });

      Object.values(updatedEnum).forEach((filterType) => {
        filterDataInitial[filterType].optionData =
          filterDataInitial[filterType].selectedData;
        filterDataInitial[filterType].checkData =
          filterDataInitial[filterType].selectedData;
      });
      setFilterData(filterDataInitial);
      setGridData(filterDataInitial);
      setChangeStatus(filterDataInitial);
    }
  };

  useEffect(() => {
    handleFilterChange();
    if (
      objFilteredRPM &&
      objFilteredRPM.uniqueRMPMDetail &&
      objFilteredRPM.uniqueRMPMDetail.length > 0
    ) {
      if (isReceipientEnable) {
        objFilteredRPM.uniqueRMPMDetail.filter((item) => item.isRowSelected)
          .length < objFilteredRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      } else {
        objFilteredRPM.uniqueRMPMDetail.filter((item) => item.isSelected)
          .length < objFilteredRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      }
    } else {
      setSelectedAll(false);
    }
  }, [changeStatus]);

  useEffect(() => {
    if (objUniqueRPM && !isRNDPIUser) {
      const updateObjUniqueRPM = Object.assign({}, objFilteredRPM);
      if (!isReceipientEnable) {
        if (isSelectedAll) {
          updateObjUniqueRPM?.uniqueRMPMDetail.map(
            (item, i) => (item.isSelected = true)
          );
        } else {
          if (
            !updateObjUniqueRPM.uniqueRMPMDetail.filter(
              (item) => !item.isSelected
            ).length
          ) {
            updateObjUniqueRPM?.uniqueRMPMDetail.map(
              (item, i) => (item.isSelected = false)
            );
          }
        }
      } else {
        if (isSelectedAll) {
          updateObjUniqueRPM?.uniqueRMPMDetail.map(
            (item, i) => (item.isRowSelected = true)
          );
        } else {
          if (
            !updateObjUniqueRPM.uniqueRMPMDetail.filter(
              (item) => !item.isRowSelected
            ).length
          ) {
            updateObjUniqueRPM?.uniqueRMPMDetail.map(
              (item, i) => (item.isRowSelected = false)
            );
          }
        }
      }
      setObjFilteredRPM(updateObjUniqueRPM);
      selectionValueChange(updateObjUniqueRPM);
    }
  }, [isSelectedAll]);

  const handleSelectionChange = (event, item) => {
    if (filterData && gridData) {
      let filterDataInitial = { ...filterData };
      let gridDataInitial = { ...gridData };

      if (filterDataInitial[item] && event) {
        let data1 = gridDataInitial[item].optionData.filter((item) =>
          event.includes(item)
        );

        if (gridDataInitial[item].optionData.length === data1.length) {
          filterDataInitial[item].modifiedFilter = false;
        } else {
          filterDataInitial[item].modifiedFilter = true;
        }
        filterDataInitial[item].selectedData = event;
      }

      let filterObj = { ...objUniqueRPM };
      if (filterObj && filterObj.uniqueRMPMDetail) {
        Object.values(rpmFilterEnum).forEach((filterType) => {
          if (filterDataInitial && filterDataInitial[filterType]) {
            filterObj.uniqueRMPMDetail = filterObj.uniqueRMPMDetail.filter(
              (item) => {
                const matchesFilter =
                  item[filterType] !== undefined &&
                  filterDataInitial[filterType].selectedData.includes(
                    item[filterType]
                  );
                return matchesFilter;
              }
            );
          }
        });
        setObjFilteredRPM(filterObj);
        // update selectedData for all filter from filterObj if modifiedFilter is false
        if (filterObj.uniqueRMPMDetail.length > 0) {
          filterObj.uniqueRMPMDetail.filter((item) => item.isRowSelected)
            .length < filterObj.uniqueRMPMDetail.length
            ? setSelectedAll(false)
            : setSelectedAll(true);
        }
      }

      Object.values(rpmFilterEnum).forEach((filterType) => {
        if (
          filterDataInitial &&
          filterDataInitial[filterType] &&
          gridDataInitial &&
          gridDataInitial[filterType]
        ) {
          filterDataInitial[filterType].checkData = [];
          filterObj.uniqueRMPMDetail.forEach((item) => {
            if (item[filterType] !== undefined) {
              filterDataInitial[filterType].checkData = [
                ...new Set([
                  ...filterDataInitial[filterType].checkData,
                  item[filterType],
                ]),
              ];

              if (filterDataInitial[filterType].modifiedFilter) {
                gridDataInitial[filterType].optionData = [
                  ...new Set([
                    ...gridDataInitial[filterType].optionData,
                    item[filterType],
                  ]),
                ];
              }
            }
          });
        }
      });
      setFilterData(filterDataInitial);
      setChangeStatus(filterDataInitial);
      setGridData(gridDataInitial);
    }
  };

  const handleFilterChange = async () => {
    handleOptionChange(objFilteredRPM);
  };

  const handleOptionChange = (Obj) => {
    let filterDataInitial = { ...filterData };
    if (
      Obj &&
      Obj.uniqueRMPMDetail &&
      objUniqueRPM &&
      objUniqueRPM.uniqueRMPMDetail &&
      filterDataInitial
    ) {
      Object.values(rpmFilterEnum).forEach((filterType) => {
        let filteredData = [
          ...new Set(
            objUniqueRPM.uniqueRMPMDetail
              .filter((i) => !Obj.uniqueRMPMDetail.includes(i))
              .map((item) => item[filterType])
          ),
        ].filter((i) => !filterDataInitial[filterType].checkData.includes(i));
        let filteredObj = [
          ...new Set(
            objUniqueRPM.uniqueRMPMDetail
              .filter((i) => !Obj.uniqueRMPMDetail.includes(i))
              .map((item) => item[filterType])
          ),
        ].filter(
          (i) => !filterDataInitial[filterType].selectedData.includes(i)
        );

        if (
          filteredData.length > 0 &&
          filteredObj.length > 0 &&
          filterDataInitial[filterType].modifiedFilter === false &&
          filteredData.length !== filteredObj.length
        ) {
          filterDataInitial[filterType].optionData =
            filterDataInitial[filterType].checkData;
        }
      });

      Object.values(rpmFilterEnum).forEach((filterType) => {
        if (
          filterDataInitial &&
          filterDataInitial[filterType] &&
          filterDataInitial[filterType].modifiedFilter === false
        ) {
          filterDataInitial[filterType].optionData = [];
          Obj.uniqueRMPMDetail.forEach((item) => {
            if (item[filterType] !== undefined) {
              filterDataInitial[filterType].optionData = [
                ...new Set([
                  ...filterDataInitial[filterType].optionData,
                  item[filterType],
                ]),
              ];
            }
          });
        }
      });
      setFilterData(filterDataInitial);
    }
  };

  const handleSortChange = (event) => {
    const itemId = event.target.id.split("-")[1];
    if (objUniqueRPM && objUniqueRPM.uniqueRMPMDetail.length > 0) {
      setIsSortActive(true);
      changeActiveSort(itemId);
    }
  };

  const changeActiveSort = async (item) => {
    let sortedArray = [];
    setActiveSort(item);
    if (item === "skuCode" || item === "material") {
      if (!isSortActive) {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "ascending",
          item,
          false
        );
      } else {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "descending",
          item,
          false
        );
        setIsSortActive(!isSortActive);
      }
    } else {
      if (!isSortActive) {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "ascending",
          item,
          true
        );
      } else {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "descending",
          item,
          true
        );
        setIsSortActive(!isSortActive);
      }
    }
    setObjFilteredRPM((prevObj) => ({
      ...prevObj,
      uniqueRMPMDetail: [...sortedArray],
    }));
  };

  const onCheckBoxAllClicked = (e) => {
    !isRNDPIUser && setSelectedAll(!isSelectedAll);
  };

  const onCheckBoxClicked = async (e, index) => {
    if (isEdit && !isRNDPIUser) {
      const updateObjUniqueRPM = Object.assign({}, objFilteredRPM);
      const objRPM = updateObjUniqueRPM.uniqueRMPMDetail.filter(
        (item, i) => i === index
      )[0];
      if (isReceipientEnable) {
        objRPM.isRowSelected = !objRPM.isRowSelected;
        updateObjUniqueRPM.uniqueRMPMDetail.filter((item) => item.isRowSelected)
          .length < updateObjUniqueRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      } else {
        objRPM.isSelected = !objRPM.isSelected;
        updateObjUniqueRPM.uniqueRMPMDetail.filter((item) => item.isSelected)
          .length < updateObjUniqueRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      }
      setObjFilteredRPM(updateObjUniqueRPM);
      selectionValueChange(updateObjUniqueRPM);
    }
  };

  return (
    <>
      <div className="bwi-rpm-grid-scroll">
        <div className="bwi-rpm-grid-scroll-child">
          <Row
            className={`bwi-rpm-grid-row bwi-rpm-grid-header-row ${
              isRNDPIUser ? "bwi-rnd-piuser-row" : ""
            }`}
          >
            <Col
              className="flex-fill bwi-rpm-grid-header-col header-checkbox"
              style={{ width: "9%" }}
            >
              <div className="header-label-country">
                <Checkbox
                  checked={isSelectedAll}
                  id={`default-checkbox`}
                  hidden={
                    isRNDPIUser ? true : isCheckboxDisabled ? true : false
                  }
                  disabled={
                    !isEdit ||
                    isDataNotAvailable ||
                    (objFilteredRPM &&
                      objFilteredRPM.uniqueRMPMDetail &&
                      objFilteredRPM.uniqueRMPMDetail.length === 0)
                  }
                  onClick={(e) => onCheckBoxAllClicked(e)}
                  className="bwi-checkbox"
                  disableRipple
                />
                {isRNDPIUser && <div className="gap-div"></div>}
                <div
                  id="bwirpmid-country"
                  onClick={handleSortChange}
                  className="bwi-rpm-country-selection bwi-rpm-grid-sort-div"
                >
                  <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                    Country
                  </span>
                  {activeSort === "country" && (
                    <img
                      src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                      className="bwi-rpm-filter-arrow"
                    />
                  )}
                </div>
              </div>
              <div className="header-label-country-filter">
                <img
                  className={`bwi-rpm-grid-country-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    countryIsOpen
                      ? "bwi-rpm-dropdown-filter-open"
                      : "bwi-rpm-dropdown-filter-close"
                  }`}
                  src={countryIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setCountryIsOpen(!countryIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.country &&
                    filterData.country.optionData
                      ? filterData.country.optionData
                      : []
                  }
                  selectedValues={filterData?.country?.selectedData}
                  gridFilterData={filterData?.country?.checkData}
                  //setSelectedValues={setCountryFilter}
                  onSelectionChange={(e) => handleSelectionChange(e, "country")}
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={countryIsOpen}
                  setIsOpen={setCountryIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-country-dropdown bwi-rpm-grid-dropdown-filter ${
                    countryIsOpen
                      ? "bwi-rpm-dropdown-open"
                      : "bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-material bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-material"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  Material
                </span>
                {activeSort === "material" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-material-description bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-materialDescription"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  Material Description
                </span>
                {activeSort === "materialDescription" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-sku bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-skuCode"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  FG Code
                </span>
                {activeSort === "skuCode" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-sku-filter">
                <img
                  className={`bwi-rpm-grid-skucode-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    skuCodeIsOpen
                      ? "bwi-rpm-dropdown-filter-open"
                      : "bwi-rpm-dropdown-filter-close"
                  }`}
                  src={skuCodeIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setSkuCodeIsOpen(!skuCodeIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.skuCode &&
                    filterData.skuCode.optionData
                      ? filterData.skuCode.optionData
                      : []
                  }
                  selectedValues={filterData?.skuCode?.selectedData}
                  gridFilterData={filterData?.skuCode?.checkData}
                  // setSelectedValues={setSkuCodeFilter}
                  onSelectionChange={(e) => handleSelectionChange(e, "skuCode")}
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={skuCodeIsOpen}
                  setIsOpen={setSkuCodeIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-skucode-dropdown bwi-rpm-grid-dropdown-filter ${
                    skuCodeIsOpen
                      ? "bwi-rpm-dropdown-open"
                      : "bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-sku-name bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-skuName"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  FG Name
                </span>
                {activeSort === "skuName" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-sku-name-filter">
                <img
                  className={`bwi-rpm-grid-skuname-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    skuNameIsOpen
                      ? "bwi-rpm-dropdown-filter-open"
                      : "bwi-rpm-dropdown-filter-close"
                  }`}
                  src={skuNameIsOpen ? polygon : polygonClose}
                  onClick={() => {
                    setSkuNameIsOpen(!skuNameIsOpen);
                  }}
                  hidden={isDataNotAvailable}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.skuName &&
                    filterData.skuName.optionData
                      ? filterData.skuName.optionData
                      : []
                  }
                  selectedValues={filterData?.skuName?.selectedData}
                  gridFilterData={filterData?.skuName?.checkData}
                  // setSelectedValues={setSkuNameFilter}
                  onSelectionChange={(e) => handleSelectionChange(e, "skuName")}
                  maxLength="487px"
                  maxDropdownHeight="180px"
                  width="200px"
                  isOpen={skuNameIsOpen}
                  setIsOpen={setSkuNameIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-skuname-dropdown bwi-rpm-grid-dropdown-filter ${
                    skuNameIsOpen
                      ? "bwi-rpm-dropdown-open"
                      : "bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-plant-code bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-plantCode"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  Plant Code
                </span>
                {activeSort === "plantCode" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-plant-code-filter">
                <img
                  className={`bwi-rpm-grid-plantcode-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    plantCodeIsOpen
                      ? "bwi-rpm-dropdown-filter-open"
                      : "bwi-rpm-dropdown-filter-close"
                  }`}
                  src={plantCodeIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setPlantCodeIsOpen(!plantCodeIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.plantCode &&
                    filterData.plantCode.optionData
                      ? filterData.plantCode.optionData
                      : []
                  }
                  selectedValues={filterData?.plantCode?.selectedData}
                  gridFilterData={filterData?.plantCode?.checkData}
                  // setSelectedValues={setPlantCodeFilter}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "plantCode")
                  }
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={plantCodeIsOpen}
                  setIsOpen={setPlantCodeIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-plantcode-dropdown bwi-rpm-grid-dropdown-filter ${
                    plantCodeIsOpen
                      ? "bwi-rpm-dropdown-open"
                      : "bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-plant-name bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-plantName"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                  Plant Name
                </span>
                {activeSort === "plantName" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-plant-name-filter">
                <img
                  className={`bwi-rpm-grid-plantname-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    plantNameIsOpen
                      ? " bwi-rpm-dropdown-filter-open"
                      : " bwi-rpm-dropdown-filter-close"
                  }`}
                  src={plantNameIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setPlantNameIsOpen(!plantNameIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.plantName &&
                    filterData.plantName.optionData
                      ? filterData.plantName.optionData
                      : []
                  }
                  selectedValues={filterData?.plantName?.selectedData}
                  gridFilterData={filterData?.plantName?.checkData}
                  // setSelectedValues={setPlantNameFilter}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "plantName")
                  }
                  maxLength="487px"
                  maxDropdownHeight="180px"
                  width="200px"
                  isOpen={plantNameIsOpen}
                  setIsOpen={setPlantNameIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-plantname-dropdown bwi-rpm-grid-dropdown-filter${
                    plantNameIsOpen
                      ? " bwi-rpm-dropdown-open"
                      : " bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-material-type bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-materialType"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span bwi-desc-length-txt">
                  Material Type
                </span>
                {activeSort === "materialType" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-material-type-filter">
                <img
                  className={`bwi-rpm-grid-materialtype-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    materialTypeIsOpen
                      ? " bwi-rpm-dropdown-filter-open"
                      : " bwi-rpm-dropdown-filter-close"
                  }`}
                  src={materialTypeIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setMaterialTypeIsOpen(!materialTypeIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.materialType &&
                    filterData.materialType.optionData
                      ? filterData.materialType.optionData
                      : []
                  }
                  selectedValues={filterData?.materialType?.selectedData}
                  gridFilterData={filterData?.materialType?.checkData}
                  // setSelectedValues={setMaterialTypeFilter}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "materialType")
                  }
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={materialTypeIsOpen}
                  setIsOpen={setMaterialTypeIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-materialtype-dropdown bwi-rpm-grid-dropdown-filter${
                    materialTypeIsOpen
                      ? " bwi-rpm-dropdown-open"
                      : " bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            {isReceipientEnable && (
              <Col
                className="flex-fill bwi-rpm-grid-header-col"
                style={{ width: "9%" }}
              >
                <div
                  className="header-label-recipient bwi-rpm-grid-sort-div"
                  onClick={handleSortChange}
                  id="dsfrpmid-recipientEmail"
                >
                  <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span">
                    {isReceipientEnable ? "Recipient" : ""}
                  </span>
                  {isReceipientEnable && activeSort === "recipientEmail" && (
                    <img
                      src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                      className="bwi-rpm-filter-arrow"
                    />
                  )}
                </div>
                <div className="header-label-recipient-filter">
                  {isReceipientEnable && (
                    <img
                      className={`bwi-rpm-grid-recipient-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle${
                        recipientIsOpen
                          ? " bwi-rpm-dropdown-filter-open"
                          : " bwi-rpm-dropdown-filter-close"
                      }`}
                      src={recipientIsOpen ? polygon : polygonClose}
                      hidden={isDataNotAvailable}
                      onClick={() => {
                        setRecipientIsOpen(!recipientIsOpen);
                      }}
                    />
                  )}
                  {isReceipientEnable && (
                    <AutoCompleteFilter
                      options={
                        filterData &&
                        filterData.recipientEmail &&
                        filterData.recipientEmail.optionData
                          ? filterData.recipientEmail.optionData
                          : []
                      }
                      selectedValues={filterData?.recipientEmail?.selectedData}
                      gridFilterData={filterData?.recipientEmail?.checkData}
                      // setSelectedValues={setRecipientFilter}
                      onSelectionChange={(e) =>
                        handleSelectionChange(e, "recipientEmail")
                      }
                      maxLength="487px"
                      maxDropdownHeight="180px"
                      width="200px"
                      isOpen={recipientIsOpen}
                      setIsOpen={setRecipientIsOpen}
                      disable={false}
                      listClassName={`bwi-rpm-grid-recipient-dropdown bwi-rpm-grid-dropdown-filter${
                        recipientIsOpen
                          ? " bwi-rpm-dropdown-open"
                          : " bwi-rpm-dropdown-close"
                      }`}
                    />
                  )}
                </div>
              </Col>
            )}
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-material-type bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-etoepocemail"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span bwi-desc-length-txt">
                  E2E Planner
                </span>
                {activeSort === "etoepocemail" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-material-type-filter">
                <img
                  className={`bwi-rpm-grid-materialtype-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    e2ePlannerIsOpen
                      ? " bwi-rpm-dropdown-filter-open"
                      : " bwi-rpm-dropdown-filter-close"
                  }`}
                  src={e2ePlannerIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setE2ePlannerIsOpen(!e2ePlannerIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData?.etoepocemail &&
                    filterData?.etoepocemail.optionData
                      ? filterData?.etoepocemail.optionData
                      : []
                  }
                  selectedValues={filterData?.etoepocemail?.selectedData}
                  gridFilterData={filterData?.etoepocemail?.checkData}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "etoepocemail")
                  }
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={e2ePlannerIsOpen}
                  setIsOpen={setE2ePlannerIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-materialtype-dropdown bwi-rpm-grid-dropdown-filter${
                    e2ePlannerIsOpen
                      ? " bwi-rpm-dropdown-open"
                      : " bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
            <Col
              className="flex-fill bwi-rpm-grid-header-col"
              style={{ width: "9%" }}
            >
              <div
                className="header-label-material-type bwi-rpm-grid-sort-div"
                onClick={handleSortChange}
                id="dsfrpmid-rpmPlannerEmail"
              >
                <span className="dsf-size12 dsf-font-weight-600 bwi-rpm-grid-span bwi-desc-length-txt">
                  RPM Planner
                </span>
                {activeSort === "rpmPlannerEmail" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="bwi-rpm-filter-arrow"
                  />
                )}
              </div>
              <div className="header-label-material-type-filter">
                <img
                  className={`bwi-rpm-grid-materialtype-dropdown-toggle bwi-rpm-grid-dropdown-filter-toggle ${
                    rpmPlannerIsOpen
                      ? " bwi-rpm-dropdown-filter-open"
                      : " bwi-rpm-dropdown-filter-close"
                  }`}
                  src={rpmPlannerIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setRpmPlannerIsOpen(!rpmPlannerIsOpen);
                  }}
                />
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData?.rpmPlannerEmail &&
                    filterData?.rpmPlannerEmail.optionData
                      ? filterData?.rpmPlannerEmail.optionData
                      : []
                  }
                  selectedValues={filterData?.rpmPlannerEmail?.selectedData}
                  gridFilterData={filterData?.rpmPlannerEmail?.checkData}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "rpmPlannerEmail")
                  }
                  maxLength="487px"
                  maxDropdownHeight="150px"
                  width="130px"
                  isOpen={rpmPlannerIsOpen}
                  setIsOpen={setRpmPlannerIsOpen}
                  disable={false}
                  listClassName={`bwi-rpm-grid-materialtype-dropdown bwi-rpm-grid-dropdown-filter${
                    rpmPlannerIsOpen
                      ? " bwi-rpm-dropdown-open"
                      : " bwi-rpm-dropdown-close"
                  }`}
                />
              </div>
            </Col>
          </Row>
          {isLoading ? (
          <ActivityIndicator />
        ) : objFilteredRPM &&
          objFilteredRPM.uniqueRMPMDetail &&
          objFilteredRPM.uniqueRMPMDetail.length > 0 ? (
          objFilteredRPM.uniqueRMPMDetail.map((item, index) => {
            return (
              // <>
              <Row
                key={`bwi-rpm-grid-row ${index}`}
                className={`bwi-rpm-grid-row ${
                  isRNDPIUser ? "bwi-rnd-piuser-row" : ""
                }`}
              >
                <Col
                  className="flex-fill bwi-rpm-grid-header-col header-checkbox"
                  style={{ width: "9%" }}
                >
                  <Checkbox
                    checked={
                      isReceipientEnable ? item.isRowSelected : item.isSelected
                    }
                    id={`default-checkbox`}
                    hidden={
                      isRNDPIUser ? true : isCheckboxDisabled ? true : false
                    }
                    disabled={!isEdit}
                    onClick={(e) => onCheckBoxClicked(e, index)}
                    className="bwi-checkbox"
                    disableRipple
                  />
                  {isRNDPIUser && <div className="gap-div"></div>}
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span">
                    {item.country}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  {item.isMaterialShared && (
                    <img
                      src={
                        require(`../../../../assets/images/Tooltip-new.svg`)
                          .default
                      }
                      alt="sort"
                      className="bwi-rpm-info-icon"
                      onClick={() => {
                        setShowModal(!showModal);
                        setCurrentItem(index);
                      }}
                    />
                  )}
                  <span
                    className={`dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span ${
                      isRNDPIUser
                        ? !item.isRnDApproved
                          ? "not-selected"
                          : item.isSelected
                          ? "non-unique-selected"
                          : "unique-selected"
                        : ""
                    }`}
                  >
                    {item.material}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span bwi-desc-length-txt">
                    {item.materialDescription}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span">
                    {item.skuCode}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span bwi-desc-length-txt">
                    {item.skuName}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span">
                    {item.plantCode}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span">
                    {item.plantName}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span">
                    {item.materialType}
                  </span>
                </Col>
                {isReceipientEnable && (
                  <Col
                    className="flex-fill bwi-rpm-grid-header-col"
                    style={{ width: "9%" }}
                  >
                    <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span bwi-desc-length-txt">
                      {isReceipientEnable
                        ? item.recipientEmail.length > 0
                          ? item.recipientEmail
                          : "-"
                        : ""}
                    </span>
                  </Col>
                )}
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span bwi-desc-length-txt">
                    {item?.etoepocemail}
                    {/* lavanya.karamsetty@unilever.com */}
                  </span>
                </Col>
                <Col
                  className="flex-fill bwi-rpm-grid-header-col"
                  style={{ width: "9%" }}
                >
                  <span className="dsf-size12 dsf-font-weight-400 bwi-rpm-grid-span bwi-desc-length-txt">
                    {item?.rpmPlannerEmail}
                  </span>
                </Col>
              </Row>
              // </>
            );
          })
        ) : (
          <div className="no-data-found">No data found</div>
        )}
        </div>
        
      </div>
      {showModal && (
        <ModalRedundantDetails
          showModal={showModal}
          setShowModal={setShowModal}
          material={objFilteredRPM?.uniqueRMPMDetail[currentItem]?.material}
          FgCode={objFilteredRPM?.uniqueRMPMDetail[currentItem]?.skuCode}
          FgDescription={objFilteredRPM?.uniqueRMPMDetail[currentItem]?.skuName}
          redundantMaterial={
            objFilteredRPM?.uniqueRMPMDetail[currentItem]?.sharedMaterialDetails
          }
        />
      )}
    </>
  );
};
export default UniqueRPM;
