import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import delistFlag from "../../../../assets/images/delist_flag.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import { InfoTooltip } from "../../../../components/Tooltip/tooltip";
import ModalWarning from "../modalWarning";
import iconEdit from "../../../../assets/images/Vector-pencil.svg";
import UserDetailsService from "../../../../service/userDetailsService";
import {
  fetchCheckDelists,
  SendEmailToEToEPlanner,
  saveCheckDelistData,
  fetchProjectWorkFlowStatus,
} from "../../../../service/projectDetails";
import { validateUnileverMailId } from "../../../../utils";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import "./checkDelists.css";
import { Receipt } from "@mui/icons-material";

const CheckDelists = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [objCheckDelist, setObjCheckDelist] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("SU Impacted");
  const { isEdit, stepStatus, userHasAccess } = props;
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [message, setMessage] = useState(
    "please review pre FG-SU the responsible E2EPlanner"
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isSendE2EEnabled, setSendE2EEnabled] = useState(true);
  const [isEditAccessEnabled, setIsEditAccessEnabled] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  // const hasEditAccess =
  //   isEdit ||
  //   email ===
  //     ? true
  //     : false;
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    retrieveCheckDelists();
  }, []);

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const retrieveCheckDelists = async () => {
    const response = await fetchCheckDelists(props.arrItem.projectId);
    if (response.status === 200) {
      let modifiedData =  {
        ...response.data,
        checkdelistdetails: response.data.checkdelistdetails.map(
          (item) => {
              return {
                ...item,
                updatedSchedulername: item.updatedSchedulername !=="" ? item.updatedSchedulername : item.masterSchedulername,
              };
          }
        ),
        impactedcheckdelistdetails: response.data.impactedcheckdelistdetails.map(
          (item) => {
              return {
                ...item,
                updatedSchedulername: item.updatedSchedulername !=="" ? item.updatedSchedulername : item.masterSchedulername,
              };
          }
        ),
        notimpactedcheckdelistdetails: response.data.notimpactedcheckdelistdetails.map(
          (item) => {
              return {
                ...item,
                updatedSchedulername: item.updatedSchedulername !=="" ? item.updatedSchedulername : item.masterSchedulername,
              };
          }
        ),
      };
      setObjCheckDelist(modifiedData);
      setEmail(modifiedData.etoepocemail);
      setIsEditAccessEnabled(
        isEdit ||
        modifiedData.etoepocemail === userDetails.email?.toLowerCase()
          ? true
          : false
      );
    } else {
      console.log("some error happened", response.data);
    }
    setIsLoading(false);
  };

  const onWarnModalOpen = (modalOpen) => {
    // if (stepStatus !== "4") {
    //   setIsWarnWindowOpen(modalOpen);
    // } else {
    onOkBtnClicked();
    // }
  };

  const onOkBtnClicked = async () => {
    const shouldUpdateWorkflow = stepStatus === "4" ? true : false;
    setIsLoading(true);
    let updateCheckDelistDetails = {};
    updateCheckDelistDetails.etoepocemail = "";
    updateCheckDelistDetails.emailMessage = "";
    updateCheckDelistDetails.appLink = window.location.href;
    updateCheckDelistDetails.piUser = "";
    updateCheckDelistDetails.projectID = objCheckDelist?.projectID;
    updateCheckDelistDetails.projectName = props.arrItem.projectName;
    updateCheckDelistDetails.checkdelistdetails =
      objCheckDelist?.checkdelistdetails;
    updateCheckDelistDetails.impactedcheckdelistdetails =
      objCheckDelist?.impactedcheckdelistdetails;
    updateCheckDelistDetails.notimpactedcheckdelistdetails =
      objCheckDelist?.notimpactedcheckdelistdetails;
    updateCheckDelistDetails.checkdelisttemplatedetail =
      objCheckDelist?.checkdelistdetails;
    const res = await saveCheckDelistData(
      props.arrItem.projectId,
      updateCheckDelistDetails,
      shouldUpdateWorkflow
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      setShowModal(false);
      props.onComplete(false);
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataCheck();
  }, [message, email, objCheckDelist]);

  const emptyValueCheck = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const dataCheck = () => {
    let isObjectValueNullCheck =
      objCheckDelist?.checkdelistdetails?.length > 0 ||
      objCheckDelist?.impactedcheckdelistdetails?.length > 0 ||
      objCheckDelist?.impactedcheckdelistdetails?.length > 0;
    setSendE2EEnabled(
      emptyValueCheck(email) ||
        emptyValueCheck(message) ||
        !isObjectValueNullCheck
    );
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setError("");
      setEmail(recepientMailId);
    } else {
      setError("The Unilever mail id does not exist");
    }
  };

  const e2ePlannerEmailHandleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRecipientValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setError("");
        setEmail(event.target.value);
        getUserDetails(event.target.value);
      } else {
        setError("Please provide valid Unilever Mail id");
      }
    }
  };

  const handleMessageValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      setMessage(event.target.value);
    }
  };

  const e2ePlannerHandleClick = async () => {
    let updateCheckDelistDetails = {};
    updateCheckDelistDetails.etoepocemail = email;
    updateCheckDelistDetails.emailMessage = message;
    updateCheckDelistDetails.appLink = window.location.href;
    updateCheckDelistDetails.piUser = userDetails.email;
    updateCheckDelistDetails.projectID = objCheckDelist?.projectID;
    updateCheckDelistDetails.projectName = props.arrItem.projectName;
    updateCheckDelistDetails.checkdelistdetails =
      objCheckDelist?.checkdelistdetails;
    updateCheckDelistDetails.impactedcheckdelistdetails =
      objCheckDelist?.impactedcheckdelistdetails;
    updateCheckDelistDetails.notimpactedcheckdelistdetails =
      objCheckDelist?.notimpactedcheckdelistdetails;
    updateCheckDelistDetails.checkdelisttemplatedetail =
      objCheckDelist?.checkdelistdetails;

    const res = await SendEmailToEToEPlanner(
      props.arrItem.projectId,
      updateCheckDelistDetails
    );
    if (res.status === 200) {
      console.log("success", res.data);
    } else {
      console.log("error is send to E2Eplanner", res.data);
    }
  };

  const handleChange = (e, i) => {
    let updatedCheckDelist;
    if (activeTab === "SU Impacted") {
      updatedCheckDelist = {
        ...objCheckDelist,
        checkdelistdetails: objCheckDelist.checkdelistdetails.map(
          (item, index) => {
            if (index === i) {
              return {
                ...item,
                updatedSchedulername: e.target.value,
              };
            }
            return item;
          }
        ),
      };
    } else if (activeTab === "Possibly SU Impacted") {
      updatedCheckDelist = {
        ...objCheckDelist,
        impactedcheckdelistdetails:
          objCheckDelist?.impactedcheckdelistdetails?.map((item, index) => {
            if (index === i) {
              return {
                ...item,
                updatedSchedulername: e.target.value,
              };
            }
            return item;
          }),
      };
    } else {
      updatedCheckDelist = {
        ...objCheckDelist,
        notimpactedcheckdelistdetails:
          objCheckDelist.notimpactedcheckdelistdetails.map((item, index) => {
            if (index === i) {
              return {
                ...item,
                updatedSchedulername: e.target.value,
              };
            }
            return item;
          }),
      };
    }

    setObjCheckDelist(updatedCheckDelist);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={onOkBtnClicked}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal bwi-modal-checkdelists">
        <div className="bwi-landing-modal-header bwi-modal-checkdelists-header">
          <div className="bwi-modal-checkdelists-header-title-div">
            <span className="bwi-modal-title dsf-span-ml">
              Review the factories (SU's/ Suppliers) where Finished Goods (FG)
              will be delisted or will have a BOM change
            </span>
            <br />
            <span className="bwi-modal-sub-title dsf-span-s">
              Below list is auto generated based on the FG movements between
              SUs, DCs and Countries in the last 12 months. Please review this
              to understand which SUs are going to be impacted by the Delist/BOM
              changes of FGs and Countries selected in the previous screen
              (Delist/BOM)
            </span>
          </div>
        </div>
        <div>
          <Row className=" bwi-e2e-planner-container items-end">
            <Col xs={3}>
              <div className="bwi-e2e-planner-field relative">
                <label>Point of Contact E2E planner</label>
                <div className="flex items-center w-100">
                  <input
                    type="text"
                    name="e2ePlanner "
                    value={email}
                    disabled={!isEdit}
                    onChange={(e) => e2ePlannerEmailHandleChange(e)}
                    className={`bwi-inventorycm-textField`}
                    onBlur={(e) => handleRecipientValueOnEnter(e)}
                    onKeyDown={(e) => handleRecipientValueOnEnter(e)}
                  />
                </div>
                {error && <span className="bwi-error-msg">{error}</span>}
              </div>
            </Col>
            <Col xs={6}>
              <div className="bwi-e2e-planner-field relative">
                <label>Message</label>
                <input
                  type="text"
                  name="message "
                  disabled={!isEdit}
                  className={`bwi-inventorycm-textField`}
                  onChange={(e) => handleMessageChange(e)}
                  value={message}
                  onBlur={(e) => handleMessageValueOnEnter(e)}
                  onKeyDown={(e) => handleMessageValueOnEnter(e)}
                />
              </div>
            </Col>
            <Col xs={3} className="flex items-center ">
              <Button
                onClick={e2ePlannerHandleClick}
                className="bwi-inventorycm-download-btn"
                variant="secondary"
                disabled={isSendE2EEnabled || !isEdit}
              >
                Send to E2E Planner
              </Button>
            </Col>
          </Row>
        </div>
        <div className="bwi-modal-checkDelist-body">
          <Row>
            <ul className=" nav bwi-modal-checkDelist_nav">
              <li
                className={activeTab === "SU Impacted" ? "active" : ""}
                onClick={() => onChangeTab("SU Impacted")}
              >
                SU Impacted
              </li>
              <li
                className={activeTab === "Possibly SU Impacted" ? "active" : ""}
                onClick={() => onChangeTab("Possibly SU Impacted")}
              >
                Possible SU Impacted
              </li>
              <li
                className={activeTab === "SU Not Impacted" ? "active" : ""}
                onClick={() => onChangeTab("SU Not Impacted")}
              >
                SU Not Impacted
              </li>
            </ul>
          </Row>
          {isLoading && (
            <div className="bwi-loader-div">
              <ActivityIndicator />
            </div>
          )}
          {!isLoading && objCheckDelist && activeTab === "SU Impacted" && (
            <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
              <div
                style={{ width: "5%" }}
                className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col pr-3"
              >
                <span className="dsf-span-sl width">FG Impacted</span>
                <InfoTooltip
                  name="info"
                  Tooltiptext={"Will be considered in BW calculation"}
                  width={"226px"}
                  height={"38px"}
                  positionClass={"tooltip-position-bottom-right-delists"}
                />
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">FG Name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "10%" }}
              >
                <span className="dsf-span-sl width">SU/ supplier Code</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "12%" }}
              >
                <span className="dsf-span-sl width">SU/ supplier name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">E2E Planner</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "10%" }}
              >
                <span className="dsf-span-sl width">FG Sold at</span>
              </div>
            </Row>
          )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "SU Impacted" &&
            objCheckDelist.checkdelistdetails &&
            objCheckDelist.checkdelistdetails.map((item, index) => {
              return (
                <>
                  <Row
                    key={item.projectID + index}
                    className="bwi-checkdelists-row bwi-checkdelists-data-row"
                  >
                    <div
                      style={{ width: "5%" }}
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <span className="dsf-span-s width">{item.plantCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "12%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <input
                        type="text"
                        value={
                          item.updatedSchedulername
                        }
                        disabled={!isEditAccessEnabled}
                        className={`dsf-textField ${
                          isEditAccessEnabled
                            ? "bwi-landing-cursor-pointer"
                            : ""
                        }`}
                        onChange={(e) => handleChange(e, index)}
                        // required
                        // onBlur={(e) => handleValueOnEnter(e,i)}
                        // onKeyDown={(e) => handleValueOnEnter(e,i)}
                      />
                      {(
                        item?.updatedSchedulername?.length > 0 &&
                        item?.updatedSchedulername !== item?.masterSchedulername
                      ) && (
                        <div className="bwi-flag-tooltip-container">
                          <img
                            src={delistFlag}
                            alt="flag"
                            className="bwi-delist-flag"
                          />
                          <div className="bwi-flag-tooltip">
                            E2E planner different information in Data Lake
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "Possibly SU Impacted" && (
              <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
                <div
                  style={{ width: "10%" }}
                  className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col pr-3"
                >
                  <span className="dsf-span-sl width">
                    Possible FG Impacted
                  </span>
                  <InfoTooltip
                    name="info"
                    width={"294px"}
                    height={"102px"}
                    Tooltiptext={
                      "These SUs supply FGs to countries in the selected list and also to other countries outside the selected list (in Delist/BOM screen). This means the production in these SUs might possibly be reduced, but might not be stopped."
                    }
                    positionClass={"tooltip-position-top-right-delists"}
                  />
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col pr-3"
                  style={{ width: "20%" }}
                >
                  <span className="dsf-span-sl width">FG Name</span>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col pr-3"
                  style={{ width: "5%" }}
                >
                  <p className="dsf-span-sl width">SU/ supplier code</p>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col pr-3"
                  style={{ width: "12%" }}
                >
                  <p className="dsf-span-sl width">SU/ supplier name</p>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col pr-3"
                  style={{ width: "20%" }}
                >
                  <span className="dsf-span-sl width">E2E Planner</span>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col pr-3"
                  style={{ width: "10%" }}
                >
                  <p className="dsf-span-sl width">FG Sold at</p>
                </div>
              </Row>
            )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "Possibly SU Impacted" &&
            objCheckDelist.impactedcheckdelistdetails &&
            objCheckDelist.impactedcheckdelistdetails.map((item, index) => {
              return (
                <>
                  <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                    <div
                      style={{ width: "10%" }}
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "5%" }}
                    >
                      <p className="dsf-span-s width">{item.plantCode}</p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "12%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <input
                        type="text"
                        value={
                          item.updatedSchedulername
                        }
                        disabled={!isEditAccessEnabled}
                        className={`dsf-textField ${
                          isEditAccessEnabled
                            ? "bwi-landing-cursor-pointer"
                            : ""
                        }`}
                        onChange={(e) => handleChange(e, index)}
                        // required
                        // onBlur={(e) => handleValueOnEnter(e,i)}
                        // onKeyDown={(e) => handleValueOnEnter(e,i)}
                      />
                      {(
                        item?.updatedSchedulername?.length > 0 &&
                        item?.updatedSchedulername !== item?.masterSchedulername
                      )  && (
                        <div className="bwi-flag-tooltip-container">
                          <img
                            src={delistFlag}
                            alt="flag"
                            className="bwi-delist-flag"
                          />
                          <div className="bwi-flag-tooltip">
                            E2E planner different information in Data Lake
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
          {!isLoading && objCheckDelist && activeTab === "SU Not Impacted" && (
            <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
              <div
                style={{ width: "5%" }}
                className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col pr-3"
              >
                <span className="dsf-span-sl width">Not Impacted</span>
                <InfoTooltip
                  name="info"
                  width={"294px"}
                  height={"82px"}
                  Tooltiptext={
                    "Based on material movement data for the past 12 months, production in these SUs will not be impacted by the renovation project."
                  }
                  positionClass={"tooltip-position-top-right-delists"}
                />
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">FG Name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "10%" }}
              >
                <p className="dsf-span-sl width">SU/ supplier code</p>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "12%" }}
              >
                <p className="dsf-span-sl width">SU/ supplier name</p>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">E2E Planner</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col pr-3"
                style={{ width: "10%" }}
              >
                <p className="dsf-span-sl width">FG Sold at</p>
              </div>
            </Row>
          )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "SU Not Impacted" &&
            objCheckDelist.notimpactedcheckdelistdetails &&
            objCheckDelist.notimpactedcheckdelistdetails.map((item, index) => {
              return (
                <>
                  <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                    <div
                      style={{ width: "5%" }}
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <p className="dsf-span-s width">{item.plantCode}</p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "12%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "20%" }}
                    >
                      <input
                        type="text"
                        value={
                          item.updatedSchedulername
                        }
                        disabled={!isEditAccessEnabled}
                        className={`dsf-textField ${
                          isEditAccessEnabled
                            ? "bwi-landing-cursor-pointer"
                            : ""
                        }`}
                        onChange={(e) => handleChange(e, index)}
                        // required
                        // onBlur={(e) => handleValueOnEnter(e,i)}
                        // onKeyDown={(e) => handleValueOnEnter(e,i)}
                      />
                      {(
                        item?.updatedSchedulername?.length > 0 &&
                        item?.updatedSchedulername !== item?.masterSchedulername
                      )  && (
                          <div className="bwi-flag-tooltip-container">
                            <img
                              src={delistFlag}
                              alt="flag"
                              className="bwi-delist-flag"
                            />
                            <div className="bwi-flag-tooltip">
                              E2E planner different information in Data Lake
                            </div>
                          </div>
                        )}
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col pr-3"
                      style={{ width: "10%" }}
                    >
                      <p className="dsf-span-s width bw-country-value dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
        </div>
        <div className="bwi-checkdelists-footer">
          <div className="bwi-footer-prevbtn-div"></div>
          <div className="bwi-modal-footer ">
            <Button
              disabled={!isEditAccessEnabled}
              className="bwi-checkdelists-footer-btn bwi-submit-button"
              variant="secondary"
              onClick={() => onWarnModalOpen(true)}
            >
              Reviewed
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckDelists;
