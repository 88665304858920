import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import TooltipDropdown from "../../../../components/Dropdown/tooltipDropdown";
import CustomOptionsDropdown from "../../../../components/Dropdown/customOptionsDropdown";
import { Checkbox } from "@mui/material";
import FGCheckModal from "./fgCheckModal";
import FGSelectionModal from "../../../../components/Autocomplete/autocompleteList";
import ModalWarning from "../modalWarning";
import ActivityIndicator from "../../../../components/Loader/loader";
import searchIcon from "../../../../assets/images/Search.svg";
import deleteList from "../../../../assets/images/deleteSelected.svg";
import { delistBOMActionTypeList } from "../../../../globalConstants";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import {
  fetchDelistBOMCountry,
  fetchDelistBOMData,
  saveDelistBOMData,
  fetchProjectWorkFlowStatus,
  resetWorkflowDetails,
} from "../../../../service/projectDetails";
import "./delistBOM.css";
import { dateTimeFormat } from "../../../../utils";

const DelistBOM = (props) => {
  const { isEdit, stepStatus } = props;
  const [delistBOMCountryList, setDelistBOMCountryList] = useState([]);
  const [dropdownCountryValue, setDropdownCountryValue] = useState("");
  const [dropdownActionValue, setDropdownActionValue] = useState("");
  const [dropdownLaunchValue, setDropdownLaunchValue] = useState("");
  const [dropdownCombinations, setDropdownCombinations] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isFGSearchReset, setIsFGSearchReset] = useState(false);
  const [isFGSearchResetEnable, setIsFGSearchResetEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedLoading, setIsSelectedLoading] = useState(false);
  const [showRightAddButton, setShowRightAddButton] = useState(false);
  const [checkboxSelectedAll, setCheckboxSelectedAll] = useState(false);
  const [delistBOMData, setDelistBOMData] = useState({});
  const [delistBOMDataInitial, setDelistBOMDataInitial] = useState({});
  const [objSelectedData, setObjSelectedData] = useState([]);
  const [objUnSelectedData, setObjUnSelectedData] = useState([]);
  const [objRemovedData, setObjRemovedData] = useState({});
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [warnWindowButton, setWarnWindowButton] = useState("");
  const [functionSelected, setFunctionSelected] = useState("Search FG Codes");
  const [actionIsOpen, setActionIsOpen] = useState(false);
  const [defaulSubmit, setDefaulSubmit] = useState(true);
  const dropdownRefAction = useRef(null);

  //Redux Start
  const arrProjectDetails = useSelector((state) => state.projectDetails);
  const dispatch = useDispatch();
  //Redux End

  /*Actions on Component Load and API call start */
  useEffect(() => {
    getDelistBOMCountryData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefAction.current &&
        !dropdownRefAction.current.contains(event.target)
      ) {
        setActionIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefAction]);

  const getDelistBOMCountryData = async () => {
    setIsSelectedLoading(true);
    const res = await fetchDelistBOMCountry(props.arrItem.projectId);
    if (res.status === 200) {
      const formattedCountryList = res.data.countries.map((countries) => ({
        value: countries,
        label: countries,
      }));
      const selectedFG = res.data.skuLists.map((item) => {
        return {
          ...item,
          isCheckoxSelected: false,
        };
      });

      setDelistBOMCountryList(formattedCountryList);
      setObjSelectedData(selectedFG);
      setIsSelectedLoading(false);
    }
  };

  const getDelistBOMData = async (key) => {
    let res = await fetchDelistBOMData(
      props.arrItem.projectId,
      dropdownCountryValue,
      dropdownActionValue,
      dropdownLaunchValue
    );
    if (res.status === 200) {
      const selectedData = res.data.filter((data) => data.isSelected);
      let unselectedData = res.data.filter((data) => !data.isSelected);
      unselectedData = unselectedData.map((item) => {
        return {
          ...item,
          isDisable: false,
        };
      });
      setDelistBOMData({
        ...delistBOMData,
        [key]: {
          selectedData,
          unselectedData,
        },
      });
      setDelistBOMDataInitial({
        ...delistBOMDataInitial,
        [key]: {
          selectedData,
          unselectedData,
        },
      });
      setDataLeft(unselectedData);
    } else {
      setDataLeft([]);
      console.log("error", res);
    }
  };
  /*Actions on Component Load and API calls end */

  /*Dropdown Change Start */
  const handleDropdownCountryChange = (value) => {
    if (dropdownCountryValue !== value) {
      setIsLoading(true);
      setDropdownCountryValue(value);
      const combinationSelected = `${value}_${dropdownActionValue}`;
      setDropdownCombinations(combinationSelected);
    }
  };

  const handleDropdownActionChange = (value) => {
    setDropdownActionValue(value);
    const launchType = delistBOMActionTypeList.find(
      (item) => item.label === value
    )?.launchType;
    setDropdownLaunchValue(launchType);
    setDropdownCountryValue("");
  };

  useEffect(() => {
    if (objSelectedData?.length > 0) {
      const unSelectedData = objSelectedData.map((item) => {
        return {
          ...item,
          isCheckoxSelected: false,
        };
      });

      setCheckboxSelectedAll(false);
      setObjSelectedData(unSelectedData);
    }
  }, [functionSelected]);

  useEffect(() => {
    if (dropdownCombinations?.length > 0) {
      if (delistBOMData && delistBOMData[dropdownCombinations]) {
        setObjUnSelectedData(
          delistBOMData[dropdownCombinations].unselectedData
        );
        setDataLeft(delistBOMData[dropdownCombinations].unselectedData);
        setIsLoading(false);
      } else {
        getDelistBOMData(dropdownCombinations);
      }
    }
  }, [dropdownCombinations]);
  /*Dropdown Change End */

  /*Get Delist BOM Data Start */
  const setDataLeft = (items) => {
    if (items) {
      let updatedData = Object.assign([], items);
      let objRemoved = Object.assign({}, objRemovedData);
      let selectedData = Object.assign([], objSelectedData);

      if (Object.keys(objRemoved).length > 0) {
        Object.keys(objRemoved).forEach((key) => {
          const country = key.split("_")[0];
          const action = key.split("_")[1];
          if (country === dropdownCountryValue) {
            if (action === dropdownActionValue && objRemoved[key].length > 0) {
              objRemoved[key].forEach((item) => {
                if (
                  updatedData.every((data) => data.skuCode !== item.skuCode)
                ) {
                  updatedData.unshift({
                    ...item,
                    isDisable: false,
                  });
                }
              });
            } else {
              objRemoved[key].forEach((item) => {
                if (
                  updatedData.every((data) => data.skuCode !== item.skuCode)
                ) {
                  if (item.isReselected) {
                    updatedData.unshift({
                      ...item,
                      isDisable: true,
                    });
                  } else {
                    updatedData.unshift({
                      ...item,
                      isDisable: false,
                    });
                  }
                } else {
                  updatedData.forEach((data) => {
                    if (data.skuCode === item.skuCode) {
                      if (item.isReselected) {
                        data.isDisable = true;
                      } else {
                        data.isDisable = false;
                      }
                    }
                  });
                }
              });
            }
          }
        });
      }

      if (selectedData?.length > 0) {
        selectedData.forEach((item) => {
          if (item.country === dropdownCountryValue) {
            if (item.action === dropdownActionValue) {
              const isItemPresent = updatedData.some(
                (data) => data.skuCode === item.skuCode
              );
              if (isItemPresent) {
                updatedData = updatedData.filter(
                  (data) => data.skuCode !== item.skuCode
                );
              }
            } else {
              const isItemPresent = updatedData.some(
                (data) => data.skuCode === item.skuCode
              );
              if (isItemPresent) {
                updatedData.forEach((data) => {
                  if (data.skuCode === item.skuCode) {
                    data.isDisable = true;
                    data.isSelected = false;
                  }
                });
              }
            }
          }
        });
      }

      updatedData = updatedData.map((item) => {
        return {
          ...item,
          label: `${item.skuCode} - ${item.skuName}`,
          value: `${item.skuCode} - ${item.skuName}`,
        };
      });

      setObjUnSelectedData(updatedData);
    }
    setIsLoading(false);
  };

  /*Get Delist BOM Data End */

  /*Left Side Data Change Logic Start */

  const handleDataAdd = async (items) => {
    let updateSelected = Object.assign([], objSelectedData);
    let updateUnSelected = Object.assign([], objUnSelectedData);
    let objRemoved = Object.assign({}, objRemovedData);

    let selectedData = items;
    if (selectedData.length > 0) {
      selectedData.forEach((item) => {
        const selectedCountry =
          item.country?.length > 0 ? item.country : dropdownCountryValue;
        const selectedAction =
          item.action?.length > 0 ? item.action : dropdownActionValue;
        updateUnSelected = updateUnSelected.filter(
          (data) => data.skuCode !== item.skuCode
        );

        const isItemPresent = updateSelected.some(
          (data) =>
            data.skuCode === item.skuCode &&
            data.country === selectedCountry &&
            data.action === selectedAction
        );
        if (!isItemPresent) {
          const key = `${selectedCountry}_${selectedAction}`;
          if (objRemoved[key]) {
            const isPresent = objRemoved[key].some(
              (data) => data.skuCode === item.skuCode
            );
            if (isPresent) {
              objRemoved[key].forEach((data) => {
                if (data.skuCode === item.skuCode) {
                  data.isReselected = true;
                }
              });
            }
          }
          updateSelected.unshift({
            skuCode: item.skuCode,
            skuName: item.skuName,
            isSelected: true,
            isCheckoxSelected: false,
            country: selectedCountry,
            action: selectedAction,
            launchDate: item.launchDate,
            reference: item.reference,
            isNewFG: true,
          });
          setDefaulSubmit(false);
        } else {
          updateSelected.forEach((data) => {
            if (
              data.skuCode === item.skuCode &&
              data.skuName === item.skuName &&
              data.country === item.country
            ) {
              Object.assign(data, {
                launchDate: item.launchDate,
                reference: item.reference,
              });
            }
          });
        }
      });
      setObjSelectedData(updateSelected);
      setObjUnSelectedData(updateUnSelected);
      setObjRemovedData(objRemoved);
      setIsFGSearchReset(true);
    }
  };

  /*Left Side Data Change Logic End */

  /*Right Side Data Change Logic Start */

  const onCheckBoxAllClicked = (e) => {
    if (objSelectedData?.length > 0) {
      setCheckboxSelectedAll(!checkboxSelectedAll);
      setShowRightAddButton(!checkboxSelectedAll);
      if (objSelectedData?.length > 0) {
        const updatedObjSelectedData = Object.assign([], objSelectedData);
        updatedObjSelectedData.map((item) => {
          item.isCheckoxSelected = !checkboxSelectedAll;
        });
        setObjSelectedData(updatedObjSelectedData);
      }
    }
  };

  const onCheckBoxClicked = async (e, index) => {
    if (objSelectedData?.length > 0) {
      const updatedObjSelectedData = Object.assign([], objSelectedData);
      updatedObjSelectedData[index].isCheckoxSelected =
        !updatedObjSelectedData[index].isCheckoxSelected;
      setObjSelectedData(updatedObjSelectedData);

      const isSelectedAll = updatedObjSelectedData.every(
        (item) => item.isCheckoxSelected
      );
      setCheckboxSelectedAll(isSelectedAll);

      const isAnySelected = updatedObjSelectedData.some(
        (item) => item.isCheckoxSelected
      );
      setShowRightAddButton(isAnySelected);
    }
  };

  const handleDataRemoved = async (func) => {
    let updateSelected = Object.assign([], objSelectedData);
    const updateUnSelected = Object.assign([], objUnSelectedData);
    const objRemoved = Object.assign({}, objRemovedData);

    let selectedData = updateSelected;

    if (func !== "reset") {
      selectedData = updateSelected.filter((item) => item.isCheckoxSelected);
    }

    if (selectedData.length > 0) {
      selectedData.forEach((item) => {
        updateSelected = updateSelected.filter(
          (data) =>
            data.skuCode !== item.skuCode ||
            data.country !== item.country ||
            data.action !== item.action
        );
        if (
          dropdownActionValue.length > 0 &&
          dropdownCountryValue.length > 0 &&
          item.country === dropdownCountryValue
        ) {
          if (item.action === dropdownActionValue) {
            updateUnSelected.unshift({
              skuCode: item.skuCode,
              skuName: item.skuName,
              label: `${item.skuCode} - ${item.skuName}`,
              value: `${item.skuCode} - ${item.skuName}`,
              isSelected: false,
              isDisable: false,
            });
          } else {
            const isPresent = updateUnSelected.some(
              (data) => data.skuCode === item.skuCode
            );
            if (!isPresent) {
              updateUnSelected.unshift({
                skuCode: item.skuCode,
                skuName: item.skuName,
                label: `${item.skuCode} - ${item.skuName}`,
                value: `${item.skuCode} - ${item.skuName}`,
                isSelected: false,
                isDisable: false,
              });
            } else {
              updateUnSelected.forEach((data) => {
                if (data.skuCode === item.skuCode) {
                  if (data.isDisable) {
                    data.isDisable = false;
                  } else {
                    data.isDisable = true;
                  }
                }
              });
            }
          }
        }
        const key = `${item.country}_${item.action}`;
        if (objRemoved[key]) {
          const isItemPresent = objRemoved[key].some(
            (data) => data.skuCode === item.skuCode
          );
          if (!isItemPresent) {
            objRemoved[key].push({
              skuCode: item.skuCode,
              skuName: item.skuName,
              isSelected: false,
            });
          } else {
            objRemoved[key].forEach((data) => {
              if (data.skuCode === item.skuCode) {
                data.isReselected = false;
              }
            });
          }
        } else {
          objRemoved[key] = [
            {
              skuCode: item.skuCode,
              skuName: item.skuName,
              isSelected: false,
              isReselected: false,
            },
          ];
        }
      });

      const isAnySelected = updateSelected.some(
        (item) => item.isCheckoxSelected
      );
      setShowRightAddButton(isAnySelected);

      setObjSelectedData(updateSelected);
      setObjUnSelectedData(updateUnSelected);
      setObjRemovedData(objRemoved);
      setCheckboxSelectedAll(false);
    }
  };
  /*Right Side Data Change Logic End */

  /*FG Check Modal Start */
  const handleResetFGSearch = (isReset) => {
    setIsFGSearchResetEnable(isReset);
    setIsFGSearchReset(false);
  };
  /*FG Check Modal End */

  /*Submit and Reset Button Start */
  const onWarnModalOpen = (modalOpen, button) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
      setWarnWindowButton(button);
    } else {
      if (button === "okButton") {
        onOkBtnClicked();
      } else {
        handleDataRemoved("reset");
      }
    }
  };

  const handleWarnModalOk = () => {
    if (warnWindowButton === "okButton") {
      onOkBtnClicked();
    } else {
      resetDelistBOMData();
    }
  };

  const resetDelistBOMData = async () => {
    setIsLoading(true);
    setIsSelectedLoading(true);
    const res = await resetWorkflowDetails(
      props.arrItem.projectId,
      props.arrItem.workflow[1].workflowId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        setObjSelectedData([]);
      }
    } else {
      console.log(res);
    }
    setIsLoading(false);
    setIsSelectedLoading(false);
  };

  const onOkBtnClicked = async () => {
    setIsLoading(true);
    setIsSelectedLoading(true);
    // const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    let delistBOMDataArr = {};
    delistBOMDataArr.projectId = props.arrItem.projectId;
    delistBOMDataArr.projectName = props.arrItem.projectName;

    if (objSelectedData?.length > 0 || Object.keys(objRemovedData).length > 0) {
      let payloadFinal = [];
      let payload = [];

      // Form payload from objSelectedData
      if (objSelectedData?.length > 0) {
        payload = objSelectedData.reduce((acc, item) => {
          const {
            country,
            action,
            skuCode,
            skuName,
            isSelected,
            launchDate,
            reference,
            isNewFG,
          } = item;
          const existingItem = acc.find(
            (obj) => obj.country === country && obj.actionCode === action
          );

          if (existingItem) {
            existingItem.skucodeandname.push({
              skuCode,
              skuName,
              isSelected,
              country: "",
              action: "",
              launchDate: launchDate === null ? "" : launchDate,
              isNewFG: isNewFG,
              reference: reference,
            });
          } else {
            const launch = delistBOMActionTypeList.filter(
              (data) => data.label === action
            )[0].launchType;
            acc.push({
              country,
              actionCode: action,
              launchType: `${launch}_manualScreen`,
              skucodeandname: [
                {
                  skuCode,
                  skuName,
                  isSelected,
                  country: "",
                  action: "",
                  launchDate: launchDate === null ? "" : launchDate,
                  isNewFG: isNewFG,
                  reference: reference,
                },
              ],
            });
          }

          return acc;
        }, []);
        payloadFinal = [...payload];
      }

      if (Object.keys(objRemovedData).length > 0) {
        for (const key in objRemovedData) {
          if (objRemovedData.hasOwnProperty(key)) {
            const [country, action] = key.split("_");
            const existingEntry = payload.find(
              (item) => item.country === country && item.actionCode === action
            );
            if (!existingEntry) {
              const launch = delistBOMActionTypeList.filter(
                (data) => data.label === action
              )[0].launchType;
              console.log("payloadFinal", payloadFinal);
              payloadFinal.push({
                country,
                actionCode: action,
                launchType: `${launch}_manualScreen`,
                skucodeandname: [],
              });
            }
          }
        }
      }

      delistBOMDataArr.delistbom = payloadFinal;

      const res = await saveDelistBOMData(
        props.arrItem.projectId,
        delistBOMDataArr
      );
      if (res.status === 200) {
        const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
        if (res1.status === 200) {
          const res = {
            projectName: props.selectedProjectName,
            workflow: res1.data,
          };
          dispatch(postUpdatedWorkFlowStatus(res));
        }
        props.onComplete(false);
      } else {
        console.log("error", res);
      }
      setIsLoading(false);
      setIsSelectedLoading(false);
    }
  };

  /*Submit and Reset Button End */

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleWarnModalOk}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal bwi-modal-delistBOM">
        <div className="bwi-landing-modal-header bwi-modal-delistBOM-header">
          <span className="bwi-modal-title dsf-span-ml dsf-font-unileverMedium">
            Existing Finished Goods impacted by renovation
          </span>
          <br />
          <span className="bwi-modal-sub-title dsf-span-s">
            Please enter or upload Finished Goods that either will be delisted
            (SKU code discontinued) or have BOM changes (SKU code continued, but
            raw and pack changes)
          </span>
        </div>
        <div className="bwi-modal-delistBOM-body">
          <Row className="bwi-delistBOM-dropdown-row">
            <Col
              xs={3}
              className="bwi-header-delistBOM-col bwi-header-delistBOM-col-act"
            >
              <p className="bwi-delistBOM-dropdown-label dsf-span-sl dsf-font-unileverMedium">
                Action
              </p>
              <div
                className="bwi-delistBOM-dropdown-div"
                ref={dropdownRefAction}
              >
                <TooltipDropdown
                  options={
                    delistBOMActionTypeList ? delistBOMActionTypeList : []
                  }
                  isOpen={actionIsOpen}
                  setIsOpen={setActionIsOpen}
                  listClassName="bwi-delistBOM-action-dropdown"
                  onSelectionChange={(value) =>
                    handleDropdownActionChange(value)
                  }
                />
              </div>
            </Col>
            <Col
              xs={9}
              className="bwi-header-delistBOM-col bwi-modal-delistBOM-col-function"
            >
              <ul
                className={`nav bwi-delistBOM-function-nav-ul ${
                  dropdownActionValue.length === 0
                    ? "bwi-delistBOM-data-disable"
                    : ""
                }`}
              >
                <li
                  key="Search FG Codes"
                  className={`dsf-font-unileverMedium ${
                    functionSelected === "Search FG Codes" ? "active" : ""
                  }
                `}
                  onClick={() => {
                    setFunctionSelected("Search FG Codes");
                    setIsFGSearchResetEnable(false);
                    setDropdownCountryValue("");
                  }}
                >
                  Search FG Codes
                </li>
                <li
                  key="Paste FG Codes in Bulk"
                  className={`dsf-font-unileverMedium ${
                    functionSelected === "Paste FG Codes in Bulk"
                      ? "active"
                      : ""
                  }
                  `}
                  onClick={() => {
                    setFunctionSelected("Paste FG Codes in Bulk");
                    setDropdownCountryValue("");
                  }}
                >
                  Paste FG Codes in Bulk
                </li>
              </ul>
            </Col>
          </Row>
          <Row
            className={`bwi-delistBOM-dropdown-row ${
              dropdownActionValue.length === 0
                ? "bwi-delistBOM-data-disable"
                : ""
            }`}
            style={{ paddingBottom: "10px" }}
          >
            <Col
              xs={5}
              className="bwi-delistBOM-list-header-left dsf-text-left"
            >
              {functionSelected === "Paste FG Codes in Bulk" ? (
                <div
                  className="bwi-display-flex-between"
                  style={{ alignContent: "end" }}
                >
                  <span className="bwi-delistBOM-list-span">
                    You can copy the desired codes and Paste the codes below
                  </span>
                  {isFGSearchResetEnable && (
                    <div
                      className="bwi-delistBOM-list-span bwi-delistBOM-search-again"
                      onClick={() => setIsFGSearchReset(true)}
                    >
                      <img
                        src={searchIcon}
                        alt="searchIcon"
                        style={{ marginRight: "2px", marginBottom: "2px" }}
                      />
                      Search again
                    </div>
                  )}
                </div>
              ) : (
                <div className="bwi-display-flex" style={{ height: "18px" }}>
                  <span className="bwi-delistBOM-list-span">
                    Country <span className="dsf-label-mandatory-field">*</span>
                    {""}
                    {dropdownCountryValue.length > 0 ? " : " : " "}
                  </span>
                  <CustomOptionsDropdown
                    options={
                      delistBOMCountryList?.length > 0
                        ? delistBOMCountryList
                        : []
                    }
                    selectedValues={dropdownCountryValue}
                    maxDropdownHeight="140px"
                    width="170px"
                    disable={dropdownActionValue.length === 0}
                    listClassName="bwi-delistBOM-country-dropdown-list"
                    onSelectionChange={(value) =>
                      handleDropdownCountryChange(value)
                    }
                  />
                </div>
              )}
            </Col>
            <Col
              xs={5}
              className="bwi-delistBOM-list-header-right dsf-text-left"
            >
              <div>
                <span className="bwi-delistBOM-list-span">Selected FGs</span>
              </div>
            </Col>
          </Row>
          <Row className={`bwi-delistBOM-dropdown-row`}>
            <Col xs={5} className="bwi-delistBOM-list-left">
              <div className="bwi-delistBOM-list-div bwi-delistBOM-list-left-div">
                <div
                  className={`bwi-delistBOM-list-left-container ${
                    isLoading ||
                    dropdownActionValue.length === 0 ||
                    (functionSelected === "Search FG Codes" &&
                      dropdownCountryValue.length === 0)
                      ? "bwi-delistBOM-align-center"
                      : ""
                  }`}
                >
                  {functionSelected === "Paste FG Codes in Bulk" ? (
                    !isLoading && (
                      <FGCheckModal
                        handleSelectedFGData={handleDataAdd}
                        projectId={props.arrItem.projectId}
                        action={dropdownActionValue}
                        isEdit={isEdit}
                        isReset={isFGSearchReset}
                        handleResetSearchEnable={handleResetFGSearch}
                      />
                    )
                  ) : dropdownActionValue.length === 0 ? (
                    <div className="bwi-delistBOM-list-left-data">
                      <span
                        className="bwi-delistBOM-list-left-data-span"
                        style={{ opacity: "0.8" }}
                      >
                        Please select any action type !!
                      </span>
                    </div>
                  ) : dropdownCountryValue.length === 0 ? (
                    <div className="bwi-delistBOM-list-left-data">
                      <span
                        className="bwi-delistBOM-list-left-data-span"
                        style={{ opacity: "0.8" }}
                      >
                        Please select any country !!
                      </span>
                    </div>
                  ) : isLoading ? (
                    <div className="bwi-loader-div">
                      <ActivityIndicator />
                    </div>
                  ) : (
                    <FGSelectionModal
                      options={objUnSelectedData}
                      selectedValues={""}
                      maxLength={10}
                      maxDropdownHeight="100%"
                      width="100%"
                      isOpen={true}
                      setIsOpen={handleResetFGSearch}
                      disable={isEdit}
                      listClassName="dsf-delistingProj-category-list"
                      onSelectionChange={handleDataAdd}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col xs={7} className="bwi-delistBOM-list-right">
              <div className="bwi-delistBOM-list-div bwi-delistBOM-list-right-div ">
                <div className="bwi-delistBOM-list-right-container ">
                  <div className="bwi-delistBOM-list-right-data bwi-delistbom-overflow">
                    <div>
                      <Row
                        className={`bwi-delistBOM-list-right-header ${
                          dropdownActionValue.length === 0
                            ? "bwi-delistBOM-table-data-disable"
                            : ""
                        }`}
                      >
                        <Col xs={1} className={`bwi-delistBOM-list-right-col`}>
                          {dropdownActionValue.length > 0 && (
                            <Checkbox
                              checked={
                                objSelectedData?.length > 0 &&
                                checkboxSelectedAll
                              }
                              id={`bwi-default-checkbox`}
                              disabled={!isEdit}
                              onClick={(e) => onCheckBoxAllClicked(e)}
                              className="bwi-checkbox"
                              disableRipple
                            />
                          )}
                        </Col>
                        <div
                          style={{ width: "10%" }}
                          className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                        >
                          Country
                        </div>
                        <div
                          style={{ width: "10%" }}
                          className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                        >
                          FG Code
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                        >
                          FG Name
                        </div>
                        <div
                          style={{ width: "16%" }}
                          className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                        >
                          Action
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                        >
                          DelistDate
                        </div>
                        <div
                          style={{ width: "15%" }}
                          className="bwi-delistBOM-list-right-col"
                        >
                          Reference
                        </div>
                      </Row>
                      <div
                        className={`bwi-delistBOM-list-right-data bwi-delistBOM-list-data ${
                          isSelectedLoading ? "bwi-delistBOM-align-center" : ""
                        }`}
                      >
                        {isSelectedLoading ? (
                          <div className="bwi-loader-div">
                            <ActivityIndicator />
                          </div>
                        ) : (
                          objSelectedData?.length > 0 &&
                          objSelectedData.map((item, index) => {
                            return (
                              <Row
                                key={index}
                                className={`bwi-delistBOM-list-right-row  ${
                                  !item.isCheckoxSelected
                                    ? "bwi-delistBOM-list-row-not-selected"
                                    : ""
                                } ${
                                  dropdownActionValue.length === 0
                                    ? "bwi-delistBOM-table-data-disable"
                                    : ""
                                }`}
                              >
                                <Col
                                  xs={1}
                                  className="bwi-delistBOM-list-right-col"
                                >
                                  <Checkbox
                                    checked={item.isCheckoxSelected}
                                    id={`bwi-default-checkbox`}
                                    disabled={!isEdit}
                                    onClick={(e) => onCheckBoxClicked(e, index)}
                                    className="bwi-checkbox"
                                    disableRipple
                                  />
                                </Col>
                                <div
                                  style={{ width: "10%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.country}
                                </div>
                                <div
                                  style={{ width: "10%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.skuCode}
                                </div>
                                <div
                                  style={{ width: "20%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.skuName}
                                </div>
                                <div
                                  style={{ width: "16%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {item.action}
                                </div>
                                <div
                                  style={{ width: "20%" }}
                                  className="bwi-delistBOM-list-right-col bwi-delistbom-right-col"
                                >
                                  {dateTimeFormat(
                                    item.launchDate,
                                    "MM/DD/YYYY"
                                  )}
                                </div>
                                <div
                                  style={{ width: "15%" }}
                                  className="bwi-delistBOM-list-right-col "
                                >
                                  {item.reference}
                                </div>
                              </Row>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  variant="secondary"
                  className="bw-delistBOMfg-btn bw-delistBOMfg-btn-position bw-delistBOMfg-right-btn bwi-delistbom-delete-button"
                  onClick={() => handleDataRemoved("dataSelected")}
                  disabled={!isEdit || !showRightAddButton}
                >
                  {" "}
                  <img
                    src={deleteList}
                    alt="buttonSelected"
                    className="bwi-delistbom-delete-icon"
                  />{" "}
                  Delete Selected
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="bwi-modal-delistBOM-footer">
          <div className="bwi-footer-prevbtn-div"></div>
          <div className="bwi-modal-footer">
            <Button
              className="bwi-delistBOM-footer-btn bwi-reset-button"
              variant="secondary"
              disabled={!isEdit || objSelectedData?.length === 0}
              onClick={() => {
                onWarnModalOpen(true, "resetButton");
              }}
            >
              Reset
            </Button>
            <Button
              className=" bwi-delistBOM-footer-btn bwi-submit-button"
              variant="secondary"
              disabled={
                !isEdit ||
                (objSelectedData?.length === 0
                  ? objRemovedData && Object.keys(objRemovedData).length === 0
                    ? true
                    : false
                  : false) ||
                defaulSubmit
              }
              onClick={() => onWarnModalOpen(true, "okButton")}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DelistBOM;
