import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/global.css";
import "./dashboard.css";
import Header from "../../layouts/Header/header";
import Card from "../../components/Card/card";
import logoProjectCount from "../../assets/images/projectActionIconNew.svg";
import logoSystemBW from "../../assets/images/SystemBWNew.svg";
import logoScenarioBW from "../../assets/images/ScenarioBW.svg";
import plusIcon from "../../assets/images/PlusNew.svg";
import logoWeeksAction from "../../assets/images/WeeksActionNew.svg";
import WorkFlowList from "./ui-components/workFlowList";
import DelistingProject from "./ui-components/DelistingProject/delistingProject";
import LandingPage from "./TaskflowScreens/workflowList";
import PendingTaskBar from "../Dashboard/TaskflowScreens/PendingTaskBar/pendingTaskBar";
import { appInsightsService } from "../../appInsights";

import { calculateSum, formatNumber } from "../../utils";
//Msal imports
import { msalInstance } from "../../index";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { onSuccessfulAuthentication } from "../../service/authentication";
import AppSnackbar from "../../components/Snackbar/snackbar";

//Redux Start
import { retrieveUserDetails } from "../../slices/userDetailsSlice";
import { retrieveProjectLists } from "../../slices/projectDetailSlice";
//Redux end

const Dashboard = () => {
  const { inProgress } = useMsal();
  const [systemBusinessWaste, setSystemBusinessWaste] = useState("");
  const [BaselineBusinessWaste, setBaselineBusinessWaste] = useState("");
  const [weeksAction, setWeeksAction] = useState("-");
  const [unit, setUnit] = useState("€");
  const [arrProject, setArrProject] = useState([]);
  const [showStackbar, setShowStackbar] = useState(false);
  const [showNotificationMsg, setShowNotificationMsg] = useState("");
  const [showDelistingProjectModal, setShowDelistingProjectModal] =
    useState(false);
  let sortingRef = useRef(false);  
  const [activeSortFunc, setActiveSortFunc] = useState('');
  const [projectSorting, setProjectSorting] = useState(false);
  //Redux Start
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const projectDetails = useSelector((state) => state.projectDetails);  
  //Redux End
  //msal
  const activeAccount = msalInstance.getActiveAccount();
  useEffect(() => {
    if (activeAccount && inProgress === InteractionStatus.None) {
      onSuccessfulAuthentication(activeAccount).then(() => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        appInsightsService.setAppInsightAuthenticatedUserId(
          userDetails?.username
        );
        getUsers(userDetails.username ?? "");
        getProjectList(userDetails.username ?? "");
      });
    }
  }, [inProgress === InteractionStatus.None]);

  const getUsers = async (usermailId) => {
    await dispatch(retrieveUserDetails(usermailId)).unwrap();
  };

  const getProjectList = async (usermailId) => {
    const projLists = await dispatch(retrieveProjectLists(usermailId)).unwrap();
    let arrProj = [];
    projLists.forEach((item) => {
      arrProj.push(item);
    });
    setArrProject(arrProj);
    setSystemBusinessWaste(formatNumber(calculateSum(projLists, "systemBW")));
    setBaselineBusinessWaste(
      formatNumber(calculateSum(projLists, "baseLineBW"))
    );
    setWeeksAction(calculateSum(projLists, "weekActions"));

    if (projLists.length > 0) {
      setShowNotificationMsg(projLists[0].notificationMessage);
      setShowStackbar(true);
    } else {
      setShowNotificationMsg("");
      setShowStackbar(false);
    }
  };
  const onPendingtaskCompletion = async () => {
    const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    getUsers(usermailId ?? "");
  };

  const updateProjectList = async () => {
    const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    getProjectList(usermailId ?? "");
    setProjectSorting(false);
  };

  const handlefilterProjectList = (projectList) =>{
    setArrProject(projectList);    
    setSystemBusinessWaste(formatNumber(calculateSum(projectList, "systemBW")));
    setBaselineBusinessWaste(
      formatNumber(calculateSum(projectList, "baseLineBW"))
    );
  }

  if (userDetails) {
    return (
      <>
        <div className="dsf-header-div">
          <Header />
        </div>
        <div className="dsf-dashbaord-summary-div">
          <div className="widgets-div">
            <div className="dsf-dashbaord-card-div">
              <Card
                headerTitle="Project Count"
                value={arrProject.length ?? "-"}
                icon={logoProjectCount}
              />
              <Card
                headerTitle="Baseline Business Waste"
                unit={BaselineBusinessWaste !== "-" ? unit : ""}
                value={`${BaselineBusinessWaste}`}
                icon={logoSystemBW}
              />
              <Card
                headerTitle="System Business Waste"
                unit={systemBusinessWaste !== "-" ? unit : ""}
                value={`${systemBusinessWaste}`}
                icon={logoSystemBW}
              />
              <Card
                headerTitle="This Week’s Actions"
                value={weeksAction}
                icon={logoWeeksAction}
              />
            </div>
          </div>
        </div>

        {showDelistingProjectModal && (
          <DelistingProject
            showModal={true}
            sortingRef={sortingRef}
            setShowModal={(isModalOpen) =>
              setShowDelistingProjectModal(isModalOpen)
            }
            updateNewProjectList={updateProjectList}
            userName={userDetails.email}
            isDataUpdate={false}
            setActiveSortFunc={setActiveSortFunc}
            setProjectSorting={setProjectSorting}
          />
        )}

        {userDetails.pendingTasks && userDetails.pendingTasks.length > 0 && (
          <PendingTaskBar
            pendingTasks={userDetails.pendingTasks}
            role={userDetails.roleName}
            onComplete={onPendingtaskCompletion}
          />
        )}
        <LandingPage
          arrProject={arrProject}
          updateProjectList={updateProjectList}
          isEdit={userDetails.roleName === "BWInnovationP&I" ? true : false}
          filterProjectList={handlefilterProjectList}
          showDelistingProjectModal={showDelistingProjectModal}
          setActiveSortFunc={setActiveSortFunc}
          activeSortFunc={activeSortFunc}
          setProjectSorting={setProjectSorting}
          projectSorting={projectSorting}
        />
        {userDetails.roleName === "BWInnovationP&I" && (
          <div className="dsf-footer-div">
            <div
              className="dsf-project-creation-div bwi-landing-cursor-pointer"
              onClick={() => {
                setShowDelistingProjectModal(!showDelistingProjectModal);
              }}
            >
              <span className="dsf-create-project-title">
                Create New Project
              </span>
              <img src={plusIcon} alt="plus-icon" />
            </div>
          </div>
        )}
        {/* {showStackbar && (
          <AppSnackbar
            open={showStackbar}
            vertical={"top"}
            horizontal={"right"}
            message={showNotificationMsg}
          />
        )} */}
      </>
    );
  } else {
    return null;
  }
};
export default Dashboard;
