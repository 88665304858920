import React, { useState, useRef, useEffect } from "react";
import polygon from "../../assets/images/Polygon1.svg";
import polygonClose from "../../assets/images/PolygonClose1.svg";
import polygonDisable from "../../assets/images/Polygon-disable.svg";
import searchIcon from "../../assets/images/Search.svg";

const TooltipDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [displayTooltip, setDisplayTooltip] = useState(-1);
  const [isOpenFirstTime, setIsOpenFirstTime] = useState(true);
  const {
    options,
    isOpen,
    setIsOpen,
    disable,
    listClassName,
    onSelectionChange,
    value
  } = props;
  const [dropDownOptions,setDropDownOptions]=useState(options);
  const [originalDropDownOption , setOriginalDropDownOption]= useState(options);

  useEffect(()=>{
    setDropDownOptions(options);
    setOriginalDropDownOption(options);
  },[props?.options])

  const toggleDropdown = () => {
    {
      !disable && setIsOpen(!isOpen);
    }
  };

  const handleSelection = (value,selectedOption) => {
    setSelectedValues(value);
    onSelectionChange(value,selectedOption);
    setIsOpen(!isOpen);
    setDisplayTooltip(-1);
  };

  const handleMouseHover = (id) => {
    setDisplayTooltip(id);
  };

  const onSeachInputChange=(e)=>{
    const searchText = e.currentTarget.value;
    const filterBySearchData = filterBySearch(searchText);
    setDropDownOptions(filterBySearchData)
  }
  const filterBySearch=(searchText)=>{
    const upperCaseSearchTerm = searchText?.trim().toUpperCase();
    if(searchText!=""){
      let filteredOptions =  originalDropDownOption?.filter((item)=>item.label.toUpperCase().includes(upperCaseSearchTerm))
      return filteredOptions
    }else{
      return originalDropDownOption
    }
  }

  return (
    <div className={`bwi-custom-tooltip-dropdown ${listClassName}`}>
      <div
        className="input-container"
        onClick={() => {
          toggleDropdown();
        }}
      >
        <input
          type="text"
          value={selectedValues?.length > 0 ? selectedValues : props?.value?.label}
          disabled={disable}
          readOnly
          placeholder="Select an action to proceed"
        />
        <img
          src={disable ? polygonDisable : isOpen ? polygon : polygonClose}
          disabled={disable}
          alt="autocomplete-dropdown-icon"
          className={`autocomplete-dropdown-icon  ${
            disable
              ? "dropdownDisable"
              : isOpen
              ? "dropdownEnable"
              : "dropdownClose"
          }`}
        />
      </div>
      {isOpen && (
        <div className="options-container">
          <div
            className="input-container">
            <img
              src={searchIcon}
              disabled={true}
              alt="autocomplete-dropdown-icon"
              className="dropdown-search-icon"

            />
            <input type="text"
              style={{borderWidth:"0px",marginLeft:"20px",}}
              className="searchTextBox"
              placeholder="Search"
              onChange={(e) => onSeachInputChange(e)}
            />
          </div>
          <div
            className="options-list"
          >
            {dropDownOptions.map((option, index) => (
              <>
                <div
                  key={option.value}
                  className={`option-row`}
                  onClick={() => handleSelection(option.label,option)}
                  onMouseEnter={() => handleMouseHover(index)}
                  onMouseLeave={()=> setDisplayTooltip(-1)}
                >
                  {option.label}
                </div>
                {index === displayTooltip && (
                  <div className="menu-tooltip">
                    {option.toolTip}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipDropdown;
