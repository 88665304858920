import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import iconEdit from "../../assets/images/Vector-pencil.svg";
import { dateTimeFormat } from "../../utils";
import "./datepicker.css";
import { StaticDatePicker } from "@mui/x-date-pickers";

const InnoflexDatePicker = (props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState("<missing>");
  const [launchDate, setLaunchDate] = useState(
    dateTimeFormat(props.launchDate, "DD/MM/YYYY")
  );
  const [showLaunchDate, setShowLaunchDate] = useState(false);
  const { onDatePickerChange } = props;
  const textFieldRef = useRef(null);
  const datePickerRef = useRef(null);
  const calenderValue = dateTimeFormat(props.launchDate, "YYYY-MM-DD");

  const handleDateChange = (value) => {
    setLaunchDate(dateTimeFormat(value.$d));
    setLaunchDate(new Date(value).toLocaleDateString("en-GB"));
    setShowLaunchDate(false);
    onDatePickerChange(
      new Date(value).toLocaleDateString("en-GB"),
      false,
      value.$d
    );
    // setShowDatePicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target) &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    const handleWindowClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleWindowClick);
    return () => {
      document.removeEventListener("click", handleWindowClick);
    };
  }, []);

  useEffect(() => {
    if (props.launchDate !== "") {
      setShowLaunchDate(false);
    } else {
      setShowLaunchDate(true);
    }
    setLaunchDate(dateTimeFormat(props.launchDate, "DD/MM/YYYY"));
  }, [props.launchDate !== ""]);

  const handleClose = () => {
    setShowDatePicker(false);
  };

  return (
    <>
      <TextField
        className={`dsf-txt-checkinnoflex ${
          showLaunchDate ? "dsf-err-txt" : "dsf-checkinnoflex-ldate"
        } ${props.isError ?? false ? "errorBorder" : "successBorder"}`}
        id={`dsf-checkinnoflex-txt`}
        value={showLaunchDate ? selectedDate : launchDate}
        variant="outlined"
        disabled
      />
      {props.isValueNull && (
        <img
          src={iconEdit}
          alt="Close-Image"
          ref={textFieldRef}
          className="dsf-checkinnoflex-date-edit-img"
          onClick={() => setShowDatePicker(!showDatePicker)}
        />
      )}
      <div ref={datePickerRef} className="dsf-custom-datepicker-parent-div">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {showDatePicker && (
            <StaticDatePicker
              views={["year", "month", "day"]}
              showDaysOutsideCurrentMonth={false}
              disableHighlightToday={false}
              className="dsf-custom-datepicker-open"
              disablePast={props.disablePast ?? true}
              disableFuture={props.disableFuture ?? false}
              //label="select date"
              value={dayjs(calenderValue.toString())}
              onChange={handleDateChange}
              onClose={handleClose}
              closeOnSelect={true}
            />
          )}
        </LocalizationProvider>
      </div>
    </>
  );
};

export default InnoflexDatePicker;
