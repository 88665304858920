import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectDetailsService from "../service/projectDetailsService";

const initialState = [];

export const retrieveProjectLists = createAsyncThunk(
  "projectDetails/retrieve",
  async (data) => {
    const res = await ProjectDetailsService.fetchProjectLists(data);
    return res.data;
  }
);

export const deleteDelistingProject = createAsyncThunk(
  "projectDetails/delete",
  async (data) => {
    const res = await ProjectDetailsService.deleteDelistingProjectDetails(data);
    return res;
  }
);

const sortProjectDetailsSlice = createSlice({
  name: "sortProjectDetails",
  initialState,
  reducers: {
    myProjectList: (state, action) => {
      console.log("action,state", action, state);
      return {
        ...state,
        projectList: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveProjectLists.fulfilled, (state, action) => {
      return {
        ...state,
        allProjectDetails: action.payload,
      };
    });
    builder.addCase(deleteDelistingProject.fulfilled, (state, action) => {
      console.log("deleteDelistingProject", action);
    });
  },
});
export const { myProjectList } = sortProjectDetailsSlice.actions;
const { reducer } = sortProjectDetailsSlice;
export default reducer;
