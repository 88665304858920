import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import "./autocomplete.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: "#EDF1F3",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    alignItems: "unset",
  },
  "& .MuiInputBase-input": {
    color: "#797775",
    fontWeight: "400",
    maxWidth: "unset",
    justifyContent: "center",
  },
}));

const AutoCompleteFilter = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValues, setSelectedValues] = useState(props.selectedValues);
  const {
    options,
    gridFilterData,
    width,
    maxDropdownHeight,
    listClassName,
    isOpen,
    setIsOpen,
  } = props;

  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedValues(props.selectedValues);
  }, [props.selectedValues]);

  useEffect(() => {
    props.onSelectionChange(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.className.split(" ")[0] !==
          listClassName.split(" ")[0] + "-toggle"
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const filteredOptions = options?.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleAllOptions = () => {
    const allOptionsSelected =
      filteredOptions.length ===
      selectedValues.filter((val) => gridFilterData.includes(val)).length;

    if (allOptionsSelected) {
      setSelectedValues([]);
    } else {
      setSelectedValues(filteredOptions.slice());
    }
  };

  const toggleValue = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  return (
    <div className={`custom-dropdown ${listClassName}`} ref={dropdownRef}>
      {isOpen && (
        <div className="options-container">
          <Search className="dsf-delistingProj-search">
            <SearchIconWrapper className="dsf-delistingProj-search-icon">
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={"Search…"}
              value={searchTerm}
              className="dsf-delistingProj-search-text"
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <div
            className="options-list"
            style={{
              maxHeight: maxDropdownHeight,
              overflowX: "hidden",
              maxWidth: width,
              width: width,
            }}
          >
            <div
              key="option-row-select-all"
              className={`option-row ${
                filteredOptions.length ===
                selectedValues?.filter((val) => gridFilterData.includes(val))
                  .length
                  ? "selected"
                  : ""
              }`}
              onClick={toggleAllOptions}
            >
              <Checkbox
                checked={
                  filteredOptions.length ===
                  selectedValues?.filter((val) => gridFilterData.includes(val))
                    .length
                }
                onChange={toggleAllOptions}
                color="primary"
              />
              Select All
            </div>
            {filteredOptions.map((option) => (
              <div
                key={option}
                className={`option-row ${
                  selectedValues?.includes(option) ? "selected" : ""
                }`}
                onClick={() => {
                  toggleValue(option);
                }}
              >
                <Checkbox
                  checked={selectedValues?.includes(option)}
                  onChange={() => toggleValue(option)}
                  color="primary"
                />
                {option.length > 0 ? option : "-"}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoCompleteFilter;
