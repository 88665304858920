import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import { Checkbox } from "@mui/material";
import {
  fetchInnoflex,
  fetchProjectWorkFlowStatus,
  saveInnoflexData,
  resetWorkflowDetails,
} from "../../../../service/projectDetails";
import {
  errorText_checkInnoflex,
  helpText_TradeWindow,
  helpText_LaunchDate,
  launchDateValidation,
  helpText_SkipValidation,
} from "../../../../globalConstants";
import { InfoTooltip } from "../../../../components/Tooltip/tooltip";
import InnoflexDatePicker from "../../../../components/DatePicker/datePickerNew";
import {
  dateTimeFormat,
  checkNullDataByObjInArray,
  checkNullDataByObjInNestedArray,
} from "../../../../utils";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import ModalWarning from "../modalWarning";
import actionPlus from "../../../../assets/images/actionPlus.svg";
import actionMinus from "../../../../assets/images/actionMinus.svg";
import TickMark from "../../../../assets/images/tick.svg";
import ModalWarnReset from '../../TaskflowScreens/modalWarningReset'
import "./checkInnoflex.css";

const CheckInnoflex = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [isOkDisabled, setOkDisabled] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [innoflexData, setInnoflexData] = useState();
  const [isLaunchDateError, setLaunchDateError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [warnWindowButton, setWarnWindowButton] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const { isEdit, stepStatus, userHasAccess } = props;
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  //Redux Start
  const arrProjectDetails = useSelector((state) => state.projectDetails);
  const userDetails = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  //Redux End

  useEffect(() => {
    setIsLoading(true);
    getCheckInnoflexData();
  }, []);

  useEffect(() => {
    if (innoflexData) {
      dateValueCheck();
    }
  }, [innoflexData]);

  const emptyValueCheck = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const dateValueCheck = () => {
    let counter = 0;
    innoflexData?.checkInnoflex?.map((item) => {
      if (!item.isValidationSkiped) {
        if (
          emptyValueCheck(item.tradeWindowStartDate) ||
          emptyValueCheck(item.tradeWindowEndDate) ||
          emptyValueCheck(item.launchDate)
        ) {
          counter++;
        }
      } else {
        if (emptyValueCheck(item.launchDate)) {
          counter++;
        }
      }
      item.fgData?.map((fgItem) => {
        if (!fgItem.isValidationSkiped) {
          if (
            emptyValueCheck(fgItem.fgTradeWindowStartDate) ||
            emptyValueCheck(fgItem.fgTradeWindowEndDate) ||
            emptyValueCheck(fgItem.launchDate)
          ) {
            counter++;
          }
        } else {
          if (emptyValueCheck(fgItem.launchDate)) {
            counter++;
          }
        }
      });
    });
    setShowError(counter && true);
  };

  const dateLimitCheck = (innoflexData) => {
    let counter = 0;
    let countData = 0;
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex?.checkInnoflex?.map((item) => {
      let count = 0;
      if (
        (Date.parse(item.launchDate) >= Date.parse(item.tradeWindowStartDate) &&
          Date.parse(item.launchDate) <= Date.parse(item.tradeWindowEndDate)) ||
        item.isValidationSkiped
      ) {
        item.isDateError = false;
      } else {
        item.isDateError = true;
        counter++;
        count++;
      }

      if (item.isValidationSkiped) {
        item.isValidationError = false;
        if (item.launchDate === "") {
          count++;
        }
      } else {
        if (
          item.tradeWindowStartDate === "" ||
          item.tradeWindowEndDate === ""
        ) {
          item.isValidationError = true;
        } else {
          item.isValidationError = false;
        }
      }

      item.fgData?.map((fgItem) => {
        if (
          (Date.parse(fgItem.launchDate) >=
            Date.parse(fgItem.fgTradeWindowStartDate) &&
            Date.parse(fgItem.launchDate) <=
              Date.parse(fgItem.fgTradeWindowEndDate)) ||
          fgItem.isValidationSkiped
        ) {
          fgItem.isDateError = false;
        } else {
          fgItem.isDateError = true;
          counter++;
          count++;
        }

        if (fgItem.isValidationSkiped) {
          fgItem.isValidationError = false;
          if (fgItem.launchDate === "") {
            count++;
          }
        } else {
          if (
            fgItem.fgTradeWindowStartDate === "" ||
            fgItem.fgTradeWindowEndDate === ""
          ) {
            fgItem.isValidationError = true;
          } else {
            fgItem.isValidationError = false;
          }
        }
      });
      if (count > 0) {
        countData++;
      }
    });
    setInnoflexData(updateInnoflex);
    setLaunchDateError(counter && true);
    setErrorCount(countData);
    return counter && true;
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const OkButtonDisabled = (innoflexData) => {
    const skipValidation =
      dateLimitCheck(innoflexData) ||
      !checkNullDataByObjInNestedArray(innoflexData.checkInnoflex, "fgData", [
        "launchDate",
      ]);
    setOkDisabled(skipValidation);
  };

  const getCheckInnoflexData = async () => {
    const res = await fetchInnoflex(props.arrItem.projectId);
    if (res.status === 200) {
      let innoflexData = {};
      innoflexData.projectID = res.data.projectId;
      innoflexData.projectName = res.data.projectName;
      innoflexData.checkInnoflex = [...res.data.checkInnoflex];
      innoflexData.countryList = [...res.data.countryList];
      innoflexData.targetLaunchDate = res.data.targetLaunchDate;
      let checkInnoflex = [];
      let countryArray = [];
      let isAllChecked = true;
      innoflexData.checkInnoflex.forEach((item) => {
        if (countryArray.indexOf(item.country) === -1) {
          countryArray.push(item.country);
        }
      });

      countryArray.forEach((country) => {
        let obj = {};
        obj.country = country;
        obj.fgData = [];
        innoflexData.checkInnoflex.forEach((item) => {
          if (item.country === country) {
            if (item.fgCode === "") {
              obj.launchDate = item.launchDate;
              obj.tradeWindowStartDate = item.tradeWindowStartDate;
              obj.tradeWindowEndDate = item.tradeWindowEndDate;
              obj.isValidationSkiped = item.isValidationSkiped;
              obj.isLaunchDateNull = item.isLaunchDateNull;
              obj.launchType = item.launchType;
              obj.fgCode = item.fgCode;
              obj.fgTradeWindowEndDate = item.fgTradeWindowEndDate;
              obj.fgTradeWindowStartDate = item.fgTradeWindowStartDate;
              if (!item.isValidationSkiped) {
                isAllChecked = false;
              }
            } else {
              obj.fgData.push(item);
            }
          }
        });
        checkInnoflex.push(obj);
      });
      innoflexData.checkInnoflex = checkInnoflex;
      setCheckedAll(isAllChecked);
      setInnoflexData(innoflexData);
      OkButtonDisabled(innoflexData);
    }
    setIsLoading(false);
  };

  const resetInnoflexData = async () => {
    setIsLoading(true);
    const res = await resetWorkflowDetails(
      props.arrItem.projectId,
      props.arrItem.workflow[0].workflowId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        getCheckInnoflexData();
      }
      // props.onComplete(false);
    } else {
      console.log(res.status);
    }
    if (checkedAll) {
      setCheckedAll(false);
    }
    setIsLoading(false);
  };

  const onOkBtnClicked = async () => {
    const shouldUpdateWorkflow = stepStatus === "4" ? true : false;
    setIsLoading(true);
    let updateInnoflex = Object.assign({}, innoflexData);
    let newCheckInnoflex = [];
    updateInnoflex.checkInnoflex.forEach((item) => {
      let obj = {};
      obj.country = item.country;
      obj.launchDate = item.launchDate;
      obj.tradeWindowStartDate = item.tradeWindowStartDate;
      obj.tradeWindowEndDate = item.tradeWindowEndDate;
      obj.isValidationSkiped = item.isValidationSkiped;
      obj.isLaunchDateNull = item.isLaunchDateNull;
      obj.launchType = item.launchType;
      obj.fgCode = item.fgCode;
      obj.fgTradeWindowEndDate = item.fgTradeWindowEndDate;
      obj.fgTradeWindowStartDate = item.fgTradeWindowStartDate;
      newCheckInnoflex.push(obj);

      item.fgData.forEach((fgItem) => {
        if (fgItem.isNewFG) {
          fgItem.isNewFG = false;
        }
        newCheckInnoflex.push(fgItem);
      });
    });
    updateInnoflex.checkInnoflex = newCheckInnoflex;
    const res = await saveInnoflexData(updateInnoflex,shouldUpdateWorkflow);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        getCheckInnoflexData();
      }
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  const onCheckBoxAllClicked = (e) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex.checkInnoflex.forEach((item) => {
      item.isValidationSkiped = e.target.checked;
      if (item.fgData && item.fgData.length > 0) {
        item.fgData.forEach((fgItem) => {
          fgItem.isValidationSkiped = e.target.checked;
        });
      }
    });
    setInnoflexData(updateInnoflex);
    setCheckedAll(e.target.checked);
    OkButtonDisabled(innoflexData);
  };

  const onCheckBoxClicked = (e, i, j, node) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    if (node === "country") {
      updateInnoflex.checkInnoflex[i].isValidationSkiped = e.target.checked;
      if (
        updateInnoflex.checkInnoflex[i].fgData &&
        updateInnoflex.checkInnoflex[i].fgData.length > 0
      ) {
        updateInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
          fgItem.isValidationSkiped = e.target.checked;
        });
      }
    } else {
      updateInnoflex.checkInnoflex[i].fgData[j].isValidationSkiped =
        e.target.checked;
      let isAllChecked = true;
      updateInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
        if (!fgItem.isValidationSkiped) {
          isAllChecked = false;
        }
      });
      updateInnoflex.checkInnoflex[i].isValidationSkiped = isAllChecked;
    }
    let isAllChecked = true;
    updateInnoflex.checkInnoflex.forEach((item) => {
      if (!item.isValidationSkiped) {
        isAllChecked = false;
      }
    });
    setCheckedAll(isAllChecked);
    setInnoflexData(updateInnoflex);
    OkButtonDisabled(innoflexData);
  };

  const handleCountryDatePickerChange = (date, index, fieldName) => {
    let updateInnoflex = Object.assign({}, innoflexData);
    updateInnoflex.checkInnoflex[index][fieldName] = dateTimeFormat(
      date,
      "MM/DD/YYYY"
    );
    if (updateInnoflex.checkInnoflex[index].fgData?.length > 0) {
      if (fieldName === "tradeWindowStartDate") {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem.fgTradeWindowStartDate = dateTimeFormat(date, "MM/DD/YYYY");
        });
      } else if (fieldName === "tradeWindowEndDate") {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem.fgTradeWindowEndDate = dateTimeFormat(date, "MM/DD/YYYY");
        });
      } else {
        updateInnoflex.checkInnoflex[index].fgData.forEach((fgItem) => {
          fgItem[fieldName] = dateTimeFormat(date, "MM/DD/YYYY");
        });
      }
    }
    setInnoflexData(updateInnoflex);
    OkButtonDisabled(innoflexData);
  };

  const handleFGDatePickerChange = (date, i, j, fieldName) => {
    setIsResetDisabled(false)
    let updatedInnoflex = { ...innoflexData };
    updatedInnoflex.checkInnoflex[i].fgData[j][fieldName] = dateTimeFormat(
      date,
      "MM/DD/YYYY"
    );

    let maxDate = "";
    let allDatesFilled = true;
    updatedInnoflex.checkInnoflex[i].fgData.forEach((fgItem) => {
      const updatedFGDate = dateTimeFormat(fgItem[fieldName], "MM/DD/YYYY");
      if (fgItem[fieldName] === "" && !fgItem.isValidationSkiped) {
        allDatesFilled = false;
      }
      if (
        maxDate === "" ||
        dateTimeFormat(updatedFGDate, "YYYY/MM/DD") >
          dateTimeFormat(maxDate, "YYYY/MM/DD")
      ) {
        maxDate = updatedFGDate;
      }
    });
    if (allDatesFilled) {
      if (fieldName === "fgTradeWindowEndDate") {
        updatedInnoflex.checkInnoflex[i].tradeWindowEndDate = maxDate;
      } else if (fieldName === "fgTradeWindowStartDate") {
        updatedInnoflex.checkInnoflex[i].tradeWindowStartDate = maxDate;
      } else {
        updatedInnoflex.checkInnoflex[i][fieldName] = maxDate;
      }
    }

    setInnoflexData(updatedInnoflex);
    OkButtonDisabled(updatedInnoflex);
  };
  const onWarnModalOpen = (modalOpen, button) => {
    // if (stepStatus !== "4") {
    //     if (button === "resetButton") {
    //     setIsResetModalOpen(modalOpen);
    //     } else {
    //       setIsWarnWindowOpen(modalOpen);
    //       setWarnWindowButton(button);
    //     }
    // } else {
      if (button === "okButton") {
        onOkBtnClicked();
      } else {
        setIsResetModalOpen(modalOpen);
      }
    // }
  };
  
  const handleResetModalConfirm = () => {
    getCheckInnoflexData();
    setIsResetModalOpen(false);
  };
 
  const handleResetModalClose = () => {
    setIsResetModalOpen(false);
  };

  

  const handleWarnModalOk = () => {
    if (warnWindowButton === "okButton") {
      onOkBtnClicked();
    } else {
      resetInnoflexData();
    }
  };

  const resetInnoflexUIData = () => {
    let updateInnoflex = Object.assign({}, innoflexData);

    updateInnoflex.checkInnoflex.forEach((item) => {
      item.tradeWindowStartDate = "";
      item.tradeWindowEndDate = "";
      item.isValidationSkiped = false;
      item.fgData.forEach((fgItem) => {
        fgItem.fgTradeWindowStartDate = "";
        fgItem.fgTradeWindowEndDate = "";
        fgItem.isValidationSkiped = false;
      });
      if (checkedAll) {
        setCheckedAll(false);
      }
    });
    OkButtonDisabled(updateInnoflex);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleWarnModalOk}
          onWarnModal={onWarnModalOpen}
        />
        
      )}
      {isResetModalOpen && (
        <ModalWarnReset
          show={true}
          onOk={handleResetModalConfirm}
          onWarnModal={handleResetModalClose}
          setIsLoading={setIsLoading}
          setIsResetDisabled={setIsResetDisabled}
        />
      )}
      <div className="bwi-modal bwi-modal-checkInnoflex">
        <div className="bwi-landing-modal-header bwi-modal-checkInnoflex-header">
          <div className="bwi-modal-checkInnoflex-header-title">
            <div>
              <span className="bwi-modal-title dsf-span-ml dsf-font-unileverMedium">
                Input Trade Window Dates for each Country and their Finished
                Goods
              </span>
              <br />
              <span className="bwi-modal-sub-title dsf-span-s">
                Please select the trading window dates for each country's & FG’s
                delist date. If you don't have the dates, you may skip this
                step. Where needed contact the marketing project lead.
              </span>
            </div>
          </div>
          {isShowError ? (
            <p className="dsf-modal-err-msg dsf-span-s">
              {errorText_checkInnoflex}
            </p>
          ) : (
            ""
          )}
          {isLaunchDateError ? (
            <p className="dsf-modal-err-msg dsf-span-s">
              {launchDateValidation}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          className={`bwi-modal-checkInnoflex-body ${
            innoflexData &&
            innoflexData.checkInnoflex &&
            innoflexData.checkInnoflex.length > 4
              ? "bwi-modal-checkInnoflex-body-content-scroll"
              : ""
          }`}
        >
          <div className="bwi-nested-innoflex-row bwi-nested-innoflex-row-header">
            <div className="bwi-nested-innoflex-flex20">
              <p className="dsf-span-sl">Country & FGs</p>
            </div>
            <div className="bwi-nested-innoflex-flex20 bwi-nested-innoflex-flex-center">
              <p className="dsf-span-sl">Delist Date</p>
              <InfoTooltip
                name="info"
                Tooltiptext={helpText_LaunchDate}
                positionClass={"tooltip-position-top"}
              />
            </div>
            <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex20">
              <div>
                <Checkbox
                  id={`default-checkbox`}
                  disabled={isEdit ? false : true}
                  className="dsf-InventoryCM-checkbox"
                  checked={checkedAll}
                  onClick={onCheckBoxAllClicked}
                  disableRipple
                />
              </div>
              <div className="bwi-nested-innoflex-flex-center">
                <p className="dsf-span-sl">Skip Trade Window Dates</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_SkipValidation}
                  positionClass={"tooltip-position-top"}
                />
              </div>
            </div>
            <div className="bwi-nested-innoflex-flex40">
              <div className="bwi-nested-innoflex-flex-center">
                <p className="dsf-span-sl">Trade Window Start Date</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_TradeWindow}
                  positionClass={"tooltip-position-top"}
                />
              </div>
              <div className="bwi-nested-innoflex-flex-center">
                <p className="dsf-span-sl">Trade Window End Date</p>
                <InfoTooltip
                  name="info"
                  Tooltiptext={helpText_TradeWindow}
                  positionClass={"tooltip-position-top"}
                />
              </div>
            </div>
          </div>
          <div className="bwi-nested-innoflex-data-row">
            {isLoading ? (
              <>
                <div className="bwi-loader-div">
                  <ActivityIndicator />
                </div>
              </>
            ) : (
              innoflexData &&
              innoflexData.checkInnoflex &&
              innoflexData.checkInnoflex.map((item, i) => {
                if (!item.fgData || item.fgData.length === 0) {
                  return null;
                }
                const isExpanded = expandedRows[i] || false;
                return (
                  <div key={i}>
                    <div className="bwi-nested-innoflex-row">
                      <div className="bwi-nested-innoflex-flex20">
                        <div className="bwi-nested-innoflex-flex8">
                          {" "}
                          {item.fgData && item.fgData.length > 0 ? (
                            <img
                              src={isExpanded ? actionMinus : actionPlus}
                              alt="action"
                              className={` ${
                                isExpanded
                                  ? "bwi-nested-innoflex-arrow-down"
                                  : "bwi-nested-innoflex-arrow"
                              } `}
                              onClick={() => toggleRowExpansion(i)}
                            />
                          ) : (
                            <span className="bwi-nested-innoflex-arrow"></span>
                          )}
                        </div>
                        <p className="dsf-span-s bwi-nested-innoflex-flex80">
                          {item.country}
                        </p>
                      </div>
                      <div className="bwi-nested-innoflex-flex20 bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-launchdatepicker-left">
                        <InnoflexDatePicker
                          key={`${item.country}-launch-date-${i}`}
                          width="100%"
                          launchDate={item.launchDate}
                          isValueNull={isEdit? true : false}
                          isError={
                            item.isValidationError
                              ? false
                              : item.launchDate === ""
                          }
                          onDatePickerChange={(value, isNull, date) =>
                            handleCountryDatePickerChange(date, i, "launchDate")
                          }
                        />
                      </div>
                      <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex20">
                        <div>
                          <Checkbox
                            id={`default-checkbox`}
                            disabled={isEdit ? false : true}
                            className="dsf-InventoryCM-checkbox"
                            disableRipple
                            checked={item.isValidationSkiped}
                            onChange={(e) =>
                              onCheckBoxClicked(e, i, 0, "country")
                            }
                          />
                        </div>
                      </div>
                      <div className="bwi-nested-innoflex-flex40">
                        <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-startdatepicker-left">
                          <InnoflexDatePicker
                            key={`${item.country}-start-date-${i}`}
                            launchDate={item.tradeWindowStartDate}
                            width="100%"
                            isValueNull={isEdit ? true : false}
                            disablePast={false}
                            disableFuture={false}
                            isError={item.isValidationError}
                            onDatePickerChange={(value, isNull, date) =>
                              handleCountryDatePickerChange(
                                date,
                                i,
                                "tradeWindowStartDate"
                              )
                            }
                          />
                        </div>
                        <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-enddatepicker-left">
                          <InnoflexDatePicker
                            key={`${item.country}-end-date-${i}`}
                            width="100%"
                            launchDate={item.tradeWindowEndDate}
                            isValueNull={isEdit ? true : false}
                            isError={item.isValidationError}
                            onDatePickerChange={(value, isNull, date) =>
                              handleCountryDatePickerChange(
                                date,
                                i,
                                "tradeWindowEndDate"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {isExpanded && item.fgData && item.fgData.length > 0 && (
                      <div className="bwi-nested-innoflex-children">
                        {item.fgData.map((child, j) => (
                          <div key={j} className="bwi-nested-innoflex-row">
                            <div className="bwi-nested-innoflex-flex20">
                              <div className="bwi-nested-innoflex-flex8">
                                <span className="bwi-nested-innoflex-arrow bwi-tick-parent">
                                  {child.isNewFG ? (
                                    <img
                                      className="bwi-tick-img"
                                      src={TickMark}
                                      alt="TickMark"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <p
                                className={`dsf-span-s bwi-nested-innoflex-flex80 ${
                                  child.isNewFG ? "bwi-text-highlight" : ""
                                }`}
                              >
                                {child.fgCode}
                              </p>
                            </div>
                            <div className="bwi-nested-innoflex-flex20 bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-launchdatepicker-left">
                              <InnoflexDatePicker
                                key={`${child.country}-launch-date-${j}`}
                                width="100%"
                                launchDate={child.launchDate}
                                isValueNull={(isEdit || (child.userHasEditAccess === userDetails.email?.toLowerCase())) ? true : false}
                                isError={
                                  child.isValidationError
                                    ? false
                                    : child.launchDate === ""
                                }
                                onDatePickerChange={(value, isNull, date) =>
                                  handleFGDatePickerChange(
                                    date,
                                    i,
                                    j,
                                    "launchDate"
                                  )
                                }
                              />
                            </div>
                            <div className="bwi-nested-innoflex-skip-validation bwi-nested-innoflex-flex20">
                              <div>
                                <Checkbox
                                  id={`default-checkbox`}
                                  disabled={isEdit  ? false : true}
                                  className="dsf-InventoryCM-checkbox"
                                  disableRipple
                                  checked={child.isValidationSkiped}
                                  onChange={(e) =>
                                    onCheckBoxClicked(e, i, j, "fg")
                                  }
                                />
                              </div>
                            </div>
                            <div className="bwi-nested-innoflex-flex40">
                              <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-startdatepicker-left">
                                <InnoflexDatePicker
                                  key={`${child.country}-start-date-${j}`}
                                  launchDate={child.fgTradeWindowStartDate}
                                  width="100%"
                                  isValueNull={isEdit ? true : false}
                                  disablePast={false}
                                  disableFuture={false}
                                  isError={child.isValidationError}
                                  onDatePickerChange={(value, isNull, date) =>
                                    handleFGDatePickerChange(
                                      date,
                                      i,
                                      j,
                                      "fgTradeWindowStartDate"
                                    )
                                  }
                                />
                              </div>
                              <div className="bwi-nested-innoflex-flex-center bwi-checkinnoflex-datepicker bwi-checkInnoflex-enddatepicker-left">
                                <InnoflexDatePicker
                                  key={`${child.country}-end-date-${j}`}
                                  width="100%"
                                  launchDate={child.fgTradeWindowEndDate}
                                  isValueNull={isEdit ? true : false}
                                  isError={child.isValidationError}
                                  onDatePickerChange={(value, isNull, date) =>
                                    handleFGDatePickerChange(
                                      date,
                                      i,
                                      j,
                                      "fgTradeWindowEndDate"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="bwi-display-flex-between bwi-modal-checkinnoflex-footer">
          <div>
            {innoflexData &&
              innoflexData.checkInnoflex &&
              innoflexData.checkInnoflex.length > 0 && (
                <span className="bwi-modal-sub-title dsf-span-s">
                  {innoflexData.checkInnoflex.length - errorCount} /{" "}
                  {innoflexData.checkInnoflex.length} Completed
                </span>
              )}
          </div>
          <div className="bwi-modal-footer">
            <Button
              className="bwi-checkinnoflex-footer-btn bwi-reset-button"
              variant="secondary"
              onClick={() => onWarnModalOpen(true, "resetButton")}
              disabled={!(isEdit || userHasAccess) || isResetDisabled}
            >
              Reset
            </Button>
            <Button
              className="bwi-checkinnoflex-footer-btn bwi-submit-button"
              variant="secondary"
              disabled={(!isEdit || isOkDisabled) && !userHasAccess}
              onClick={() => onWarnModalOpen(true, "okButton")}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckInnoflex;
